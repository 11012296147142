<template>
  <v-container fluid class="grey lighten-2 flexContainer">
    <v-card class="w-full h-full d-flex flex-column" rounded="0">
      <v-card-title class="pa-1 pl-2 secondary">
        <slot name="title">
          <v-btn
            x-small
            fab
            dark
            rounded
            color="secondarytext"
            elevation="1"
            class="mr-2 mt-1 mb-1"
            @click="refresh"
          >
            <v-icon class="secondary--text"> mdi-autorenew </v-icon>
          </v-btn>
          <span class="font-weight-medium text-h6 secondarytext--text">
            {{ $t('Navigation.usermanagement_children.' + $route.name) }}
          </span>
        </slot>
      </v-card-title>
      <v-divider />
      <v-tabs v-model="tab">
        <v-tab>
          <v-icon class="mr-2"> ci-user </v-icon>
          {{ $t('UserManagement.users') }}
        </v-tab>
        <v-tab>
          <v-icon class="mr-2"> ci-users </v-icon>
          {{ $t('UserManagement.groups') }}
        </v-tab>
      </v-tabs>
      <v-divider />
      <v-tabs-items v-model="tab" class="grey lighten-3">
        <v-tab-item>
          <v-row
            style="flex: initial; flex-shrink: 0"
            class="ma-0 pt-0 pb-0 white"
          >
            <div class="white pb-4 pa-2 pl-4 pr-4 filterRow">
              <div class="filters">
                <v-select
                  v-model="userFilterMode"
                  hide-details
                  :debounce-search="0"
                  :clearable="false"
                  :items="userFilterModes"
                  :label="$t('UserManagement.searchMode')"
                  item-text="text"
                  item-value="value"
                  class="text-caption mr-2"
                  @change="checkCombobox"
                />
                <v-select
                  v-if="userFilterMode == 'profile'"
                  v-model="profileVariable"
                  hide-details
                  :debounce-search="0"
                  :clearable="false"
                  :items="profileVariables"
                  :label="$t('UserManagement.profileVariable')"
                  item-text="text"
                  item-value="value"
                  class="text-caption mr-2"
                />
                <v-text-field
                  v-if="userFilterMode == 'profile'"
                  v-model="profileVariableFilter"
                  :name="generateGuid()"
                  :disabled="profileVariable == undefined"
                  hide-details
                  :label="$t('UserManagement.profileVariableValue')"
                  :clearable="true"
                  class="text-caption mr-2"
                  @click:clear="clearValueAndQuery"
                  @keydown.enter="prepareQuery"
                />
                <v-text-field
                  v-if="userFilterMode == 'name'"
                  v-model="userName"
                  :name="generateGuid()"
                  hide-details
                  :label="$t('UserManagement.userName')"
                  :clearable="true"
                  class="text-caption mr-2"
                  @click:clear="clearValueAndQuery"
                  @keydown.enter="prepareQuery"
                />
                <v-text-field
                  v-if="userFilterMode == 'email'"
                  v-model="userMail"
                  :name="generateGuid()"
                  hide-details
                  :label="$t('UserManagement.eMail')"
                  :clearable="true"
                  class="text-caption mr-2"
                  @keydown.enter="prepareQuery"
                  @click:clear="clearValueAndQuery"
                />
              </div>
              <v-btn
                required
                :loading="loading"
                :disabled="
                  userFilterMode == 'profile' && profileVariable == undefined
                "
                @click="prepareQuery"
              >
                <v-icon left color="primary"> mdi-magnify </v-icon>
                {{ $t('UserManagement.search') }}
              </v-btn>
            </div>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col
              cols="12"
              class="fill-height pa-0"
              style="
                height: calc(100vh - 48px - 24px - 44px - 36px - 54px - 72px);
                overflow-y: auto;
                overflow-x: hidden;
              "
            >
              <argus-grid
                ref="usersGrid"
                :items="usersItems"
                :headers="usersHeaders"
                :load-data="searchUsers"
                :elevation="3"
                :show-select="true"
                :total-results="totalResultsUsers"
                :loading="loading"
                selection-key="Name"
              >
                <template slot="header">
                  <thead>
                    <tr>
                      <th
                        :colspan="usersHeaders.length + 1"
                        class="pl-4"
                        style="box-shadow: none"
                      >
                        <v-tooltip bottom transition="fade-transition">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="primary"
                              :disabled="loading"
                              v-bind="attrs"
                              @click="addUser"
                              v-on="on"
                            >
                              <v-icon>ci-add_user</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('UserManagement.addUser') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom transition="fade-transition">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-if="usersGridIsActive"
                              icon
                              color="primary"
                              :disabled="
                                loading || $refs.usersGrid.selected.length != 1
                              "
                              v-bind="attrs"
                              @click="editUser($refs.usersGrid.selected[0])"
                              v-on="on"
                            >
                              <v-icon>ci-edit_pen</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('UserManagement.editUser') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom transition="fade-transition">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-if="usersGridIsActive"
                              icon
                              color="primary"
                              :disabled="
                                loading || $refs.usersGrid.selected.length != 1
                              "
                              v-bind="attrs"
                              @click="deleteUser($refs.usersGrid.selected[0])"
                              v-on="on"
                            >
                              <v-icon>ci-trash</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('UserManagement.deleteUser') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom transition="fade-transition">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-if="usersGridIsActive"
                              icon
                              color="primary"
                              :disabled="
                                loading || $refs.usersGrid.selected.length != 1
                              "
                              v-bind="attrs"
                              @click="
                                changePassword($refs.usersGrid.selected[0])
                              "
                              v-on="on"
                            >
                              <v-icon>ci-password_reset</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('UserManagement.changePassword') }}</span>
                        </v-tooltip>
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th>
                        {{ $t('UserManagement.userName') }}
                      </th>
                      <th>
                        {{ $t('UserManagement.eMail') }}
                      </th>
                    </tr>
                  </thead>
                </template>
              </argus-grid>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row
            style="flex: initial; flex-shrink: 0"
            class="ma-0 pt-0 pb-0 white"
          >
            <div class="white pb-4 pa-2 pl-4 pr-4 filterRow">
              <div class="filters">
                <v-text-field
                  v-model="groupName"
                  :name="generateGuid()"
                  hide-details
                  :label="$t('UserManagement.groupName')"
                  :clearable="true"
                  class="text-caption mr-2"
                  @keydown.enter="searchGroups"
                  @click:clear="clearValueAndQuery"
                />
              </div>
              <v-btn required :loading="loading" @click="searchGroups">
                <v-icon left color="primary"> mdi-magnify </v-icon>
                {{ $t('UserManagement.search') }}
              </v-btn>
            </div>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col
              cols="12"
              class="fill-height pa-0"
              style="
                height: calc(100vh - 48px - 24px - 44px - 36px - 54px - 72px);
                overflow-y: auto;
                overflow-x: hidden;
              "
            >
              <argus-grid
                ref="groupsGrid"
                :items="groupsItems"
                :headers="groupsHeaders"
                :load-data="searchGroups"
                :show-select="true"
                :hide-footer="true"
                :elevation="3"
                :total-results="totalResultsGroups"
                selection-key="Name"
              >
                <template slot="renderer.Buttons">
                  <v-btn icon color="blue">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template slot="header">
                  <thead>
                    <tr>
                      <th
                        :colspan="usersHeaders.length + 1"
                        class="pl-4"
                        style="box-shadow: none"
                      >
                        <v-tooltip bottom transition="fade-transition">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="primary"
                              :disabled="loading"
                              v-bind="attrs"
                              @click="addGroup"
                              v-on="on"
                            >
                              <v-icon>ci-add_user</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('UserManagement.addGroup') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom transition="fade-transition">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-if="!!$refs.groupsGrid"
                              icon
                              color="primary"
                              :disabled="
                                loading || $refs.groupsGrid.selected.length != 1
                              "
                              v-bind="attrs"
                              @click="editGroup($refs.groupsGrid.selected[0])"
                              v-on="on"
                            >
                              <v-icon>ci-edit_pen</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('UserManagement.editGroup') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom transition="fade-transition">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-if="!!$refs.groupsGrid"
                              icon
                              color="primary"
                              :disabled="
                                loading || $refs.groupsGrid.selected.length != 1
                              "
                              v-bind="attrs"
                              @click="deleteGroup($refs.groupsGrid.selected[0])"
                              v-on="on"
                            >
                              <v-icon>ci-trash</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('UserManagement.deleteGroup') }}</span>
                        </v-tooltip>
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th>
                        {{ $t('UserManagement.groupName') }}
                      </th>
                    </tr>
                  </thead>
                </template>
              </argus-grid>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog
      v-model="userDialog"
      hide-overlay
      width="500"
      transition="fade-transition"
      persistent
    >
      <v-card>
        <v-card-text class="pa-0">
          <v-tabs v-model="userTab" dark background-color="primary">
            <v-tab>
              {{ $t('UserManagement.userInformation') }}
            </v-tab>
            <v-tab>
              {{ $t('UserManagement.groups') }}
            </v-tab>
            <v-tab>
              {{ $t('UserManagement.profile') }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="userTab">
            <v-tab-item>
              <v-card :elevation="0">
                <v-card-text :class="newUser ? 'addUser' : 'editUser'">
                  <v-form
                    ref="formUser"
                    v-model="user_valid"
                    lazy-validation
                    @submit.prevent="saveUser"
                  >
                    <v-text-field
                      v-model="user.Name"
                      :disabled="!newUser"
                      :clearable="false"
                      :rules="rules_Name"
                      validate-on-blur
                      :name="generateGuid()"
                      autocomplete="username"
                      nofill
                      class="searchField text-caption pt-0 mb-2"
                      :label="$t('UserManagement.userName')"
                      :hint="$t('UserManagement.mandatoryField')"
                      persistent-hint
                    >
                      <template #append>
                        <v-icon small class="mt-1"> mdi-asterisk </v-icon>
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-model="user.Comment"
                      :name="generateGuid()"
                      :clearable="true"
                      class="searchField text-caption pt-0 mb-2"
                      :label="$t('UserManagement.comment')"
                    />
                    <v-text-field
                      v-model="user.Email"
                      :name="generateGuid()"
                      :clearable="true"
                      :rules="rules_Mail"
                      validate-on-blur
                      class="searchField text-caption pt-0 mb-2"
                      :label="$t('UserManagement.eMail')"
                      :hint="$t('UserManagement.mandatoryField')"
                      persistent-hint
                    >
                      <template #append>
                        <v-icon small class="mt-1"> mdi-asterisk </v-icon>
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-if="newUser"
                      v-model="user.Password"
                      :name="generateGuid()"
                      autocomplete="new-password"
                      nofill
                      type="password"
                      :clearable="true"
                      :rules="rules_Password"
                      validate-on-blur
                      class="searchField text-caption pt-0"
                      :label="$t('UserManagement.password')"
                      :hint="$t('UserManagement.mandatoryField')"
                      persistent-hint
                    >
                      <template #append>
                        <v-icon small class="mt-1"> mdi-asterisk </v-icon>
                      </template>
                    </v-text-field>
                    <div
                      class="d-flex mt-4 grey lighten-3 black--text flex-column px-5 pt-3 pb-9 text-body-2"
                    >
                      <span class="mb-4 text-body-1">{{
                        $t('UserManagement.passwordRules')
                      }}</span>
                      <div>
                        <v-icon class="mr-2 text--grey text--darken-1" small>
                          mdi-circle
                        </v-icon>
                        <span>
                          {{
                            $t('UserManagement.passwordMinimumLength', {
                              length: passwordMinLength,
                            })
                          }}
                        </span>
                      </div>
                      <div
                        v-if="!!passwordRegexMessage"
                        class="mt-4 d-flex align-start"
                      >
                        <v-icon class="mr-2 text--grey text--darken-1" small>
                          mdi-circle
                        </v-icon>
                        <span>
                          {{ passwordRegexMessage }}
                        </span>
                      </div>
                    </div>
                    <v-checkbox
                      v-model="user.IsDisabled"
                      hide-details
                      :label="$t('UserManagement.disabled')"
                    />
                    <v-checkbox
                      v-model="user.IsLocked"
                      hide-details
                      :label="$t('UserManagement.blocked')"
                      :disabled="!user.IsLocked"
                    />
                    <v-checkbox
                      v-model="user.ChangePassword"
                      hide-details
                      :label="$t('UserManagement.cannotChangePassword')"
                      @change="
                        comboBoxChangePasswordChanged(user.ChangePassword)
                      "
                    />
                    <v-checkbox
                      v-model="user.ChangeNextLogon"
                      hide-details
                      :disabled="user.ChangePassword"
                      :label="$t('UserManagement.mustChangePassword')"
                    />
                    <v-checkbox
                      v-model="user.NeverExpires"
                      hide-details
                      :disabled="user.ChangePassword"
                      :label="$t('UserManagement.passwordNeverExpires')"
                    />
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card :elevation="0">
                <v-card-text :class="newUser ? 'addUser' : 'editUser'">
                  <v-checkbox
                    v-for="g in user.Groups"
                    :id="'group-' + g.Name"
                    :key="g.Name"
                    v-model="g.Checked"
                    :label="g.Name"
                    hide-details
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card :elevation="0">
                <v-card-text
                  v-if="!!user.Profile"
                  :class="newUser ? 'addUser' : 'editUser'"
                >
                  <v-text-field
                    v-for="property in allProfiles"
                    :id="'profile-' + property.ProfileName"
                    :key="property.ProfileName"
                    v-model="user.Profile[property.ProfileName]"
                    :label="property.ProfileName"
                    :placeholder="property.DefaultValue"
                    hide-details
                  />
                  <span v-if="Object.keys(allProfiles).length == 0">
                    {{ $t('UserManagement.noProfileVariables') }}
                  </span>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn class="mr-2" @click="userDialog = false">
            {{ $t('Global.cancel') }}
          </v-btn>
          <v-btn color="success" :loading="loading" @click="saveUser">
            {{ $t('Global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="groupDialog"
      hide-overlay
      width="500"
      transition="fade-transition"
      persistent
    >
      <v-card>
        <v-card-text class="pa-0">
          <v-tabs v-model="groupTab" dark background-color="primary">
            <v-tab>
              {{ $t('UserManagement.groupInformation') }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="groupTab">
            <v-tab-item>
              <v-card :elevation="0">
                <v-card-text
                  v-if="!!group.Members"
                  :class="newGroup ? 'addGroup' : 'editGroup'"
                >
                  <v-form
                    ref="formGroup"
                    v-model="group_valid"
                    lazy-validation
                    @submit.prevent="saveGroup"
                  >
                    <v-text-field
                      v-model="group.Name"
                      :label="$t('UserManagement.groupName')"
                      :disabled="!newGroup"
                      :rules="rules_Name"
                      :hint="$t('UserManagement.mandatoryField')"
                      persistent-hint
                      validate-on-blur
                      :name="generateGuid()"
                    >
                      <template #append>
                        <v-icon small class="mt-1"> mdi-asterisk </v-icon>
                      </template>
                    </v-text-field>
                  </v-form>
                  <v-divider />
                  <v-checkbox
                    v-for="u in allUsers"
                    :id="'user-' + u.Name"
                    :key="u.Name"
                    v-model="group.Members[u.Name]"
                    :label="u.Name"
                    hide-details
                    dense
                    style="margin-top: 4px"
                    @change="updateMembers"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn class="mr-2" @click="groupDialog = false">
            {{ $t('Global.cancel') }}
          </v-btn>
          <v-btn color="success" :loading="loading" @click="saveGroup">
            {{ $t('Global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changePasswordDialog"
      width="500"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            dark
            color="primary"
            class="text-center primarytext--text"
            style="display: block"
          >
            {{ $t('UserManagement.changePassword') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="changePasswordDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 pb-0">
          <v-form
            ref="formChangePassword"
            v-model="changePassword_valid"
            lazy-validation
            @submit.prevent="savePassword"
          >
            <v-text-field
              v-model="tempUser.Password"
              :name="generateGuid()"
              type="password"
              single-line
              :label="$t('UserManagement.newPassword')"
              :clearable="true"
              :rules="rules_Password"
              validate-on-blur
              class="text-caption"
            />
            <v-text-field
              v-model="tempUser.ConfirmPassword"
              :name="generateGuid()"
              type="password"
              single-line
              :label="$t('UserManagement.confirmNewPassword')"
              :clearable="true"
              :rules="rules_ConfirmPassword"
              validate-on-blur
              class="text-caption"
            />
            <v-row class="ma-0 w-full mb-2">
              <div
                class="d-flex grey lighten-3 black--text flex-column px-5 pt-3 pb-9 text-body-2 w-full"
              >
                <span class="mb-4 text-body-1">{{
                  $t('UserManagement.passwordRules')
                }}</span>
                <div>
                  <v-icon class="mr-2 text--grey text--darken-1" small>
                    mdi-circle
                  </v-icon>
                  <span>
                    {{
                      $t('UserManagement.passwordMinimumLength', {
                        length: passwordMinLength,
                      })
                    }}
                  </span>
                </div>
                <div
                  v-if="!!passwordRegexMessage"
                  class="mt-4 d-flex align-start"
                >
                  <v-icon class="mr-2 text--grey text--darken-1" small>
                    mdi-circle
                  </v-icon>
                  <span>
                    {{ passwordRegexMessage }}
                  </span>
                </div>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            required
            :disabled="!changePassword_valid"
            :loading="loading"
            color="secondary"
            class="secondarytext--text mr-3 mb-2"
            @click="savePassword"
          >
            <v-icon left> ci-edit_pen </v-icon>
            {{ $t('UserManagement.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
      transition="fade-transition"
    >
      <v-card color="primary" dark>
        <v-card-title
          class="text-center primarytext--text"
          style="display: block"
        >
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <Confirm ref="confirmDelete" />
  </v-container>
</template>

<script>
import { v4 } from 'uuid'
import UserManagementService from '../services/UserManagementService'

export default {
  components: {
    ArgusGrid: () => import('../components/ArgusGrid'),
    Confirm: () => import('../components/Confirm'),
  },
  data() {
    return {
      usersItems: [],
      groupsItems: [],
      loading: false,
      userDialog: false,
      groupDialog: false,
      changePasswordDialog: false,
      tempUser: {
        Password: '',
      },
      newUser: false,
      newGroup: false,
      allUsers: [],
      tab: 0,
      userTab: 0,
      groupTab: 0,
      userName: '',
      userMail: '',
      userFilterMode: 'name',
      groupName: '',
      user: {},
      group: {},
      user_valid: true,
      group_valid: true,
      allGroups: [],
      allProfiles: {},
      members: [],
      nonMembers: [],
      originalMembers: [],
      totalResultsUsers: 0,
      totalResultsGroups: 0,
      usersGridIsActive: false,
      groupsGridIsActive: false,
      changePassword_valid: false,
      passwordMinLength: 6,
      passwordRegex: '',
      passwordRegexMessage: '',
      profileVariable: undefined,
      profileVariables: [],
      profileVariableFilter: '',
    }
  },
  computed: {
    rules_Mail() {
      this.$i18n.locale
      return [
        (v) => !!v || this.$t('Login.emailRequired'),
        (v) =>
          (!!v && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v)) ||
          this.$t('Login.invalidEmail'),
      ]
    },
    rules_Name() {
      this.$i18n.locale
      return [(v) => !!v || this.$t('Login.nameRequired')]
    },
    rules_ConfirmPassword() {
      this.$i18n.locale
      return [
        (v) =>
          v == this.tempUser.Password || this.$t('Login.passwordsDoNotMatch'),
      ]
    },
    rules_Password() {
      this.$i18n.locale
      let result = []
      let inst = this

      if (this.passwordRegex != '') {
        let isValid = true
        try {
          new RegExp(this.passwordRegex)
        } catch (e) {
          isValid = false
        }

        if (isValid) {
          let regex = new RegExp(this.passwordRegex)

          result = [
            (v) => !!v || this.$t('Login.passwordRequired'),
            (v) =>
              (!!v && v.length >= inst.passwordMinLength) ||
              this.$t('Login.passwordTooShort'),
            (v) => (!!v && regex.test(v)) || this.passwordRegexMessage,
          ]
        } else {
          result = [
            (v) => !!v || this.$t('Login.passwordRequired'),
            (v) =>
              (!!v && v.length >= inst.passwordMinLength) ||
              this.$t('Login.passwordTooShort'),
          ]
        }
      } else {
        result = [
          (v) => !!v || this.$t('Login.passwordRequired'),
          (v) =>
            (!!v && v.length >= inst.passwordMinLength) ||
            this.$t('Login.passwordTooShort'),
        ]
      }

      return result
    },
    usersHeaders() {
      this.$i18n.locale
      return [
        {
          text: this.$t('UserManagement.userName'),
          value: 'Name',
          sortable: false,
          width: '50px',
        },
        {
          text: this.$t('UserManagement.eMail'),
          value: 'Email',
          sortable: false,
        },
      ]
    },
    groupsHeaders() {
      this.$i18n.locale
      return [
        {
          text: this.$t('UserManagement.groupName'),
          value: 'Name',
          sortable: false,
        },
      ]
    },
    userFilterModes() {
      this.$i18n.locale
      let modes = []

      modes.push({
        text: this.$t('UserManagement.userName'),
        value: 'name',
      })

      modes.push({
        text: this.$t('UserManagement.eMail'),
        value: 'email',
      })

      modes.push({
        text: this.$t('UserManagement.profileVariable'),
        value: 'profile',
      })

      return modes
    },
  },
  mounted() {
    this.getPasswordRules()
  },
  methods: {
    refresh() {
      if (this.tab == 0) {
        this.userName = ''
        this.userMail = ''
        this.profileVariableFilter = ''
        this.profileVariable = undefined
        if (this.$refs.usersGrid.options.page == 1) {
          this.searchUsers()
        } else {
          this.$refs.usersGrid.setPage(1)
        }
      } else {
        this.groupName = ''
        this.searchGroups()
      }
    },
    getPasswordRules() {
      let inst = this

      let request = {}

      request.Domain = this.$store.getters.siteConfig.DOMAIN
      request.Issuer = 'Built-In'

      UserManagementService.getPasswordRules(request).then((response) => {
        inst.passwordMinLength = response.data.MinimumLength
        inst.passwordRegex = response.data.Regex
        inst.passwordRegexMessage = response.data.RegexMessage
      })
    },
    clearValueAndQuery() {
      if (this.tab == 0) {
        if (this.userFilterMode == 'name') {
          this.userName = ''
        }
        if (this.userFilterMode == 'name') {
          this.userMail = ''
        }
        if (this.userFilterMode == 'profile') {
          this.profileVariableFilter = ''
        }
        this.searchUsers()
      } else {
        this.groupName = ''
        this.searchGroups()
      }
    },
    generateGuid() {
      return v4()
    },
    prepareQuery() {
      if (this.$refs.usersGrid.options.page == 1) {
        this.searchUsers()
      } else {
        this.$refs.usersGrid.setPage(1)
      }
    },
    comboBoxChangePasswordChanged(checked) {
      if (checked) {
        this.user.ChangeNextLogon = false
        this.user.NeverExpires = true
      }
    },
    updateMembers() {
      this.members = []
      this.nonMembers = []

      this.allUsers.forEach((element) => {
        if (this.group.Members[element.Name]) {
          this.members.push(element)
        } else {
          this.nonMembers.push(element)
        }
      })
    },
    searchUsers() {
      let inst = this
      let request = {}

      this.usersGridIsActive = true

      request.PageIndex = this.$refs.usersGrid.options.page
      request.PageSize = this.$refs.usersGrid.options.itemsPerPage

      inst.loading = true

      if (this.userFilterMode == 'name') {
        request.Filter = this.userName

        if (request.Filter == '' || request.Filter == null) {
          request.Filter = '*'
        }

        UserManagementService.findUsersByName(request)
          .then((resp) => {
            inst.usersItems = resp.data.data
            inst.totalResultsUsers = resp.data.itemsCount
          })
          .finally(function () {
            inst.loading = false
          })
      }

      if (this.userFilterMode == 'profile') {
        request.Filter = this.profileVariableFilter
        request.ProfileVariable = this.profileVariable

        if (request.Filter == '' || request.Filter == null) {
          request.Filter = '*'
        }

        if (this.profileVariable == undefined) {
          inst.loading = false
          return
        }

        UserManagementService.findUsersByProfile(request)
          .then((resp) => {
            inst.usersItems = resp.data.data
            inst.totalResultsUsers = resp.data.itemsCount
          })
          .finally(function () {
            inst.loading = false
          })
      }

      if (this.userFilterMode == 'email') {
        request.Filter = this.userMail

        if (request.Filter == '' || request.Filter == null) {
          request.Filter = '*'
        }

        UserManagementService.findUsersByEmail(request)
          .then((resp) => {
            inst.usersItems = resp.data.data
            inst.totalResultsUsers = resp.data.itemsCount
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    searchGroups() {
      let inst = this
      let request = {}

      this.groupsGridIsActive = true

      if (this.groupName == null) {
        this.groupName = ''
      }

      request.PageIndex = 1
      request.PageSize = 100000
      request.Filter = []

      if (this.groupName == '') {
        request.Filter.push({ Name: 'Name', Value: '*' })
      } else {
        request.Filter.push({ Name: 'Name', Value: this.groupName })
      }

      inst.loading = true

      UserManagementService.searchGroups(request)
        .then((resp) => {
          inst.groupsItems = resp.data.data
          inst.totalResultsGroups = resp.data.itemsCount
        })
        .finally(function () {
          inst.loading = false
        })
    },
    checkCombobox() {
      if (this.userFilterMode == 'profile') {
        this.getProfileVariables()
      }
    },
    getProfileVariables() {
      this.loading = true
      let inst = this

      UserManagementService.getProfileVariables()
        .then((resp) => {
          inst.profileVariables = resp.data.map((q) => {
            return {
              text: q.ProfileName,
              value: q.ProfileName,
            }
          })
          if (inst.profileVariables.length > 0) {
            inst.profileVariable = inst.profileVariables[0].value
          }
        })
        .finally(function () {
          inst.loading = false
        })
    },
    searchAllTotals() {
      let inst = this

      return new Promise((resolve, reject) => {
        let request = {}

        request.PageIndex = 1
        request.PageSize = 100000
        request.Filter = ''

        inst.loading = true

        UserManagementService.findUsersByName(request)
          .then((resp) => {
            inst.allUsers = resp.data.data
            inst.totalResultsUsers = resp.data.itemsCount

            request.Filter = []

            UserManagementService.searchGroups(request)
              .then((resp) => {
                inst.allGroups = resp.data.data

                UserManagementService.getProfileVariables()
                  .then((resp) => {
                    inst.allProfiles = resp.data
                    resolve()
                  })
                  .catch(function (error) {
                    reject(error)
                  })
                  .finally(function () {
                    inst.loading = false
                  })
              })
              .catch(function (error) {
                inst.loading = false
                reject(error)
              })
          })
          .catch(function (error) {
            inst.loading = false
            reject(error)
          })
      })
    },
    searchUserTotals() {
      let inst = this

      return new Promise((resolve, reject) => {
        let request = {}

        request.PageIndex = 1
        request.PageSize = 100000
        request.Filter = []

        inst.loading = true

        UserManagementService.searchGroups(request)
          .then((resp) => {
            inst.allGroups = resp.data.data
            UserManagementService.getProfileVariables()
              .then((resp) => {
                inst.allProfiles = resp.data
                resolve()
              })
              .catch(function (error) {
                reject(error)
              })
              .finally(function () {
                inst.loading = false
              })
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    searchGroupTotals() {
      let inst = this

      return new Promise((resolve, reject) => {
        let request = {}

        request.PageIndex = 1
        request.PageSize = 100000
        request.Filter = []

        inst.loading = true

        UserManagementService.findUsers(request)
          .then((resp) => {
            inst.allUsers = resp.data.data
            inst.totalResultsUsers = resp.data.itemsCount
            resolve()
          })
          .catch(function (error) {
            inst.loading = false
            reject(error)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    setGroups(groups) {
      this.user.Groups = JSON.parse(JSON.stringify(this.allGroups))
      this.user.Groups.forEach((element) => {
        element.Checked = groups.indexOf(element.Name) != -1
      })
    },
    editUser(user) {
      let inst = this
      this.newUser = false
      this.userTab = 0
      this.user = JSON.parse(JSON.stringify(user))
      this.searchUserTotals().then(function () {
        inst.setGroups(user.Groups)
        inst.comboBoxChangePasswordChanged(user.ChangePassword)
        inst.userDialog = true
      })
    },
    addUser() {
      let inst = this
      this.searchUserTotals().then(function () {
        inst.newUser = true
        let newUser = {}

        newUser.Groups = inst.allGroups
        newUser.Profile = {}

        inst.allProfiles.forEach((element) => {
          newUser.Profile[element.ProfileName] = element.Default
        })

        newUser.ChangeNextLogon = false
        newUser.ChangePassword = false
        newUser.Comment = ''
        newUser.Email = ''
        newUser.IsDisabled = false
        newUser.IsLocked = false
        newUser.Name = ''
        newUser.NeverExpires = false
        newUser.Password = ''

        inst.user = newUser
        inst.userDialog = true
      })
    },
    saveUser() {
      this.user_valid = this.$refs.formUser.validate()

      if (this.user_valid) {
        let inst = this
        let request = JSON.parse(JSON.stringify(this.user))
        let groups = []

        inst.loading = false

        this.user.Groups.forEach((element) => {
          if (element.Checked) {
            groups.push(element.Name)
          }
        })

        request.Groups = groups

        if (this.newUser) {
          UserManagementService.addUser(request)
            .then(function () {
              inst.userDialog = false

              inst.$toast.success(inst.$t('UserManagement.userCreated'), {
                position: 'top-right',
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: 'button',
                icon: 'v-icon mdi mdi-check',
                rtl: false,
                toastClassName: 'customToast',
              })

              inst.searchUsers()
              inst.user = {}
              inst.$refs.usersGrid.selected = []

              inst.$refs.formUser.reset()
            })
            .finally(function () {
              inst.loading = false
            })
        } else {
          UserManagementService.editUser(request)
            .then(function () {
              inst.userDialog = false

              inst.$toast.success(inst.$t('UserManagement.userUpdated'), {
                position: 'top-right',
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: 'button',
                icon: 'v-icon mdi mdi-check',
                rtl: false,
                toastClassName: 'customToast',
              })

              inst.searchUsers()
              inst.user = {}
              inst.$refs.usersGrid.selected = []

              inst.$refs.formUser.reset()
            })
            .finally(function () {
              inst.loading = false
            })
        }
      }
    },
    deleteUser(user) {
      this.$refs.confirmDelete
        .open(
          this.$t('UserManagement.deleteUser'),
          this.$t('Global.areYouSure'),
          {
            color: 'red',
          }
        )
        .then((res) => {
          if (res.result) {
            let inst = this
            this.loading = true

            UserManagementService.deleteUser(user)
              .then(function () {
                inst.$toast.success(inst.$t('UserManagement.userDeleted'), {
                  position: 'top-right',
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: 'button',
                  icon: 'v-icon mdi mdi-check',
                  rtl: false,
                  toastClassName: 'customToast',
                })

                inst.$refs.usersGrid.selected = []
                inst.searchUsers()
              })
              .finally(function () {
                inst.loading = false
              })
          }
        })
    },
    changePassword(user) {
      this.tempUser = user
      this.tempUser.ConfirmPassword = ''
      this.changePasswordDialog = true
    },
    savePassword() {
      this.changePassword_valid = this.$refs.formChangePassword.validate()
      if (this.changePassword_valid) {
        let inst = this
        this.loading = true
        UserManagementService.changePassword(this.tempUser)
          .then(function () {
            inst.changePasswordDialog = false
            inst.searchUsers()
            inst.$refs.usersGrid.selected = []
          })
          .finally(function () {
            inst.loading = false
            inst.userDialog = false
          })
      }
    },
    addGroup() {
      let inst = this

      this.searchGroupTotals().then(function () {
        inst.newGroup = true
        let group = {}
        group.Name = ''
        group.Users = []
        inst.group = group
        inst.setMembers(inst.group.Users)
        inst.groupDialog = true
      })
    },
    setMembers(users) {
      this.group.Members = {}
      this.allUsers.forEach((element) => {
        this.group.Members[element.Name] = users.indexOf(element.Name) != -1
      })
      this.updateMembers()
    },
    editGroup(group) {
      let inst = this

      this.searchGroupTotals().then(function () {
        inst.newGroup = false
        inst.originalMembers = JSON.parse(JSON.stringify(group)).Users
        inst.group = JSON.parse(JSON.stringify(group))
        inst.setMembers(inst.group.Users)
        inst.groupDialog = true
      })
    },
    deleteGroup(group) {
      this.$refs.confirmDelete
        .open(
          this.$t('UserManagement.deleteGroup'),
          this.$t('Global.areYouSure'),
          {
            color: 'red',
          }
        )
        .then((res) => {
          if (res.result) {
            let inst = this
            this.loading = true

            UserManagementService.deleteGroup(group)
              .then(function () {
                inst.$toast.success(inst.$t('UserManagement.groupDeleted'), {
                  position: 'top-right',
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: 'button',
                  icon: 'v-icon mdi mdi-check',
                  rtl: false,
                  toastClassName: 'customToast',
                })

                inst.$refs.groupsGrid.selected = []
                inst.searchGroups()
              })
              .finally(function () {
                inst.loading = false
              })
          }
        })
    },
    saveGroup() {
      this.group_valid = this.$refs.formGroup.validate()

      if (this.group_valid) {
        let inst = this
        let request = JSON.parse(JSON.stringify(this.group))
        request.Users = []

        this.members.forEach((element) => {
          request.Users.push(element.Name)
        })

        if (this.newGroup) {
          UserManagementService.addGroup(request)
            .then(function () {
              inst.groupDialog = false

              inst.$toast.success(inst.$t('UserManagement.groupCreated'), {
                position: 'top-right',
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: 'button',
                icon: 'v-icon mdi mdi-check',
                rtl: false,
                toastClassName: 'customToast',
              })

              inst.group = {}
              inst.$refs.groupsGrid.selected = []
              inst.searchGroups()

              inst.$refs.formGroup.reset()
            })
            .finally(function () {
              inst.loading = false
            })
        } else {
          UserManagementService.updateGroup(request)
            .then(function () {
              inst.groupDialog = false

              inst.$toast.success(inst.$t('UserManagement.groupUpdated'), {
                position: 'top-right',
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: 'button',
                icon: 'v-icon mdi mdi-check',
                rtl: false,
                toastClassName: 'customToast',
              })

              inst.group = {}
              inst.$refs.groupsGrid.selected = []
              inst.searchGroups()

              inst.$refs.formGroup.reset()
            })
            .finally(function () {
              inst.loading = false
            })
        }
      }
    },
  },
}
</script>

<style>
.addUser,
.addGroup {
  height: 540px;
  overflow: auto;
}

.editUser,
.editGroup {
  height: 475px;
  overflow: auto;
}

.v-data-table col:first-of-type {
  width: 56px;
}
</style>
