import Vue from 'vue'
import Vuex from 'vuex'
import AuthenticationService from '../services/AuthenticationService'
import ApiService from '../services/ApiService'
import SiteService from '../services/SiteService'
import EasView from '../views/Eas.vue'
import DocflowView from '../views/Docflow.vue'
import StatisticsView from '../views/Statistics.vue'
import UserManagementAuditView from '../views/UserManagementAudit.vue'
import UserManagementView from '../views/UserManagement.vue'
import TraceabilityAuditView from '../views/TraceabilityAudit.vue'
import TraceabilityAttestationsView from '../views/TraceabilityAttestations.vue'
import TraceabilityConformityView from '../views/TraceabilityConformity.vue'
import AuthorizationAclView from '../views/AuthorizationAcl.vue'
import AuthorizationConstraintsView from '../views/AuthorizationConstraints.vue'
import Router from '../router'
import createPersistedState from 'vuex-persistedstate'
import showToast from '../toasts'

import { MFA_TYPES } from '../components/MFA.vue'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    configStatus: '',
    config: undefined,
    lang: undefined,
    token: undefined,
    routes: [],
    siteConfig: {},
    messages: undefined,
    mfaType: MFA_TYPES.NONE,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['lang', 'token', 'status', 'mfaType'],
      key: 'EAS-' + window.location.origin + window.location.pathname,
    }),
  ],
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token) {
      state.status = 'success'
      state.token = token
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = undefined
      state.configStatus = ''
      state.routes = []
      ApiService.setHeader(null)
    },
    language(state, l) {
      state.lang = l
    },
    siteConfig(state, c) {
      state.siteConfig = c
    },
    routes(state, routes) {
      state.routes = routes
    },
    config_request(state) {
      state.configStatus = 'loading'
    },
    config_success(state, config) {
      state.configStatus = 'success'
      state.config = config
    },
    config_error(state) {
      state.configStatus = 'error'
    },
    messages(state, messages) {
      state.messages = messages
    },
    mfaType(state, type) {
      state.mfaType = type
    },
  },
  actions: {
    setLanguages({ commit }, inst) {
      let messages = {}

      const getJson = (language) => {
        return new Promise(function (resolve) {
          fetch('locales/' + language + '.json?v=' + new Date().getTime())
            .then((r) => r.json())
            .then((json) => {
              messages[language] = json

              resolve()
            })
            .catch(() => {
              resolve()
            })
        })
      }

      this.getters.siteConfig.LANGUAGES.forEach((language) => {
        getJson(language).then(() => {
          if (inst != undefined && inst.$i18n != undefined) {
            inst.$i18n.setLocaleMessage(language, messages[language])
          }

          commit('messages', messages)
        })
      })
    },
    setMfaType({ commit }, type) {
      commit('mfaType', type)
    },
    login({ commit }, formValues) {
      let store = this

      return new Promise((resolve, reject) => {
        commit('auth_request')

        const data = {}
        data.DomainName = store.getters.siteConfig.DOMAIN
        data.UserName = formValues.name
        data.Password = formValues.password
        data.MfaCode = formValues.otp
        data.CookiePath = store.getters.siteConfig.COOKIE_PATH

        AuthenticationService.login(data)
          .then((resp) => {
            const token = JSON.parse(resp.data)

            commit('auth_success', JSON.stringify(token))

            ApiService.setHeader(resp.data)

            resolve(resp)
          })
          .catch((err) => {
            commit('auth_error')

            reject(err)
          })
      })
    },
    sso({ commit }, ticket) {
      let store = this

      return new Promise((resolve, reject) => {
        commit('auth_request')

        const data = {}
        data.Ticket = ticket
        data.CookiePath = store.getters.siteConfig.COOKIE_PATH

        AuthenticationService.sso(data)
          .then((resp) => {
            const token = JSON.parse(resp.data)

            commit('auth_success', JSON.stringify(token))

            ApiService.setHeader(resp.data)

            resolve(resp)
          })
          .catch((err) => {
            commit('auth_error')

            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        resolve()
      })
    },
    setLanguage({ commit }, l) {
      commit('language', l)
    },
    setSiteConfig({ commit }, c) {
      commit('siteConfig', c)
    },
    setRoutes({ commit }, inst) {
      let store = this

      ApiService.setHeader(store.getters.token)

      return new Promise((resolve, reject) => {
        commit('config_request')

        SiteService.getConfig()
          .then((resp) => {
            let config = resp.data
            let routes = []

            commit('config_success', config)

            if (store.getters.siteConfig.DOCFLOW_ENABLED) {
              let docflowRoute = {}
              docflowRoute.path = '/docflow'
              docflowRoute.name = 'Navigation.docflow'
              docflowRoute.component = DocflowView
              docflowRoute.meta = {}
              docflowRoute.meta.requiresAuth = true
              docflowRoute.meta.visible = true

              routes.push(docflowRoute)
            }

            let umGroup = {}
            umGroup.name = 'Navigation.usermanagement'
            umGroup.path = '/usermanagement/:id'
            umGroup.children = []
            umGroup.meta = {}
            umGroup.meta.requiresAuth = true

            let adminGroup = {}
            adminGroup.name = 'Navigation.admin'
            adminGroup.path = '/admin/:id'
            adminGroup.children = []
            adminGroup.meta = {}
            adminGroup.meta.requiresAuth = true

            let easGroup = {}
            easGroup.name = 'Navigation.eas'
            easGroup.path = '/EAS/:id'
            easGroup.component = EasView
            easGroup.children = []
            easGroup.meta = {}
            easGroup.meta.requiresAuth = true
            easGroup.meta.parent = 'Navigation.eas'

            config.Groups.forEach(function (item) {
              if (item.Name == 'EAS') {
                item.SubGroups.filter((q) =>
                  item.ConfigItem.Config.DocumentTypes.find(
                    (y) => y.docType == q.Name && !y.exceptionMessage
                  )
                ).forEach(function (group) {
                  let route = {}
                  route.path = '/EAS/' + encodeURIComponent(group.Name)
                  route.name = group.Name
                  route.component = EasView
                  route.meta = {}
                  route.meta.requiresAuth = true
                  route.meta.type = 'EAS'

                  easGroup.children.push(route)
                })
                item.SubGroups.filter((q) =>
                  item.ConfigItem.Config.DocumentTypes.find(
                    (y) => y.docType == q.Name && !!y.exceptionMessage
                  )
                ).forEach(function (group) {
                  const docType = item.ConfigItem.Config.DocumentTypes.find(
                    (y) => y.docType == group.Name
                  )
                  showToast(
                    { message: group.Name + ' - ' + docType.exceptionMessage },
                    inst
                  )
                })
              }

              if (item.Name == 'Management') {
                let stats = {}
                stats.path = '/admin/statistics'
                stats.name = 'Statistics'
                stats.component = StatisticsView
                stats.meta = {}
                stats.meta.requiresAuth = true

                let traceabilityAudit = {}
                traceabilityAudit.path = '/admin/audit'
                traceabilityAudit.name = 'TraceabilityAudit'
                traceabilityAudit.component = TraceabilityAuditView
                traceabilityAudit.meta = {}
                traceabilityAudit.meta.requiresAuth = true

                let traceabilityAttestations = {}
                traceabilityAttestations.path = '/admin/attestations'
                traceabilityAttestations.name = 'Attestations'
                traceabilityAttestations.component =
                  TraceabilityAttestationsView
                traceabilityAttestations.meta = {}
                traceabilityAttestations.meta.requiresAuth = true

                let traceabilityConformity = {}
                traceabilityConformity.path = '/admin/conformity'
                traceabilityConformity.name = 'Conformity'
                traceabilityConformity.component = TraceabilityConformityView
                traceabilityConformity.meta = {}
                traceabilityConformity.meta.requiresAuth = true

                let authorizationAcl = {}
                authorizationAcl.path = '/usermanagement/acl'
                authorizationAcl.name = 'Authorization'
                authorizationAcl.component = AuthorizationAclView
                authorizationAcl.meta = {}
                authorizationAcl.meta.requiresAuth = true

                let authorizationConstraints = {}
                authorizationConstraints.path = '/admin/constraints'
                authorizationConstraints.name = 'Constraints'
                authorizationConstraints.component =
                  AuthorizationConstraintsView
                authorizationConstraints.meta = {}
                authorizationConstraints.meta.requiresAuth = true

                item.SubGroups.forEach(function (group) {
                  switch (group.Name) {
                    case 'Reports':
                      adminGroup.children.push(stats)
                      break
                    case 'TraceabilityAudit':
                      adminGroup.children.push(traceabilityAudit)
                      break
                    case 'Attestations':
                      adminGroup.children.push(traceabilityAttestations)
                      break
                    case 'Conformity':
                      adminGroup.children.push(traceabilityConformity)
                      break
                    case 'Authorization':
                      umGroup.children.push(authorizationAcl)
                      break
                    case 'Constraints':
                      adminGroup.children.push(authorizationConstraints)
                      break
                  }
                })
              }

              if (item.Name == 'UserManagement') {
                let um = {}
                um.path = '/usermanagement/users'
                um.name = 'Users'
                um.component = UserManagementView
                um.meta = {}
                um.meta.requiresAuth = true

                let audit = {}
                audit.path = '/usermanagement/audit'
                audit.name = 'UserManagementAudit'
                audit.meta = {}
                audit.component = UserManagementAuditView
                audit.meta.requiresAuth = true

                item.SubGroups.forEach(function (group) {
                  switch (group.Name) {
                    case 'Audit':
                      umGroup.children.push(audit)
                      break
                    case 'Users':
                      umGroup.children.push(um)
                      break
                  }
                })
              }
            })

            if (easGroup.children && easGroup.children.length > 0) {
              routes.push(easGroup)
            }

            if (adminGroup.children && adminGroup.children.length > 0) {
              routes.push(adminGroup)
            }

            if (umGroup.children && umGroup.children.length > 0) {
              routes.push(umGroup)
            }

            routes = Router.options.routes.concat(routes)

            commit('routes', routes)
            resolve()
          })
          .catch((error) => {
            commit('config_error')

            showToast(error, inst)

            reject(error)
          })
      })
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    user: (state) => {
      if (state.token) {
        return JSON.parse(state.token).UserName || ''
      } else {
        return ''
      }
    },
    token: (state) => {
      return state.token
    },
    configStatus: (state) => state.configStatus,
    config: (state) => state.config,
    lang: (state) => state.lang,
    siteConfig: (state) => state.siteConfig,
    authStatus: (state) => state.status,
    messages: (state) => state.messages,
    routes: (state) => state.routes,
    getDocType: (state) => (name) => {
      let docType = undefined
      if (state.configStatus == 'success') {
        state.config.Groups.forEach(function (item) {
          if (item.Name == 'EAS') {
            item.ConfigItem.Config.DocumentTypes.forEach((documentType) => {
              if (documentType.docType == name) {
                docType = documentType
              }
            })
          }
        })
      }
      return docType
    },
  },
})
