<template>
  <v-container fill-height fluid class="grey lighten-4">
    <v-row class="text-center">
      <v-col class="pt-0" cols="12">
        <div v-if="!!htmlContent" v-html="htmlContent" />
        <div v-else>
          <img src="img/logo.png" />
          <h1 class="display-1 mt-6 font-weight-bold">Welcome to</h1>
          <p>EAS - Electronic Archive System</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    htmlContent() {
      let html = null
      this.$i18n.locale

      if (
        this.$store.getters.siteConfig[
          'LANDING_HTML.' + this.$i18n.locale.toUpperCase()
        ]
      ) {
        html =
          this.$store.getters.siteConfig[
            'LANDING_HTML.' + this.$i18n.locale.toUpperCase()
          ]
      }

      return html
    },
  },
}
</script>
