import ApiService from '../services/ApiService'

const URL_GETDOCUMENTTYPESAUDIT = '/api/Traceability/GetDocumentTypesAudit'
const URL_GETDOCUMENTTYPESATTESTATION =
  '/api/Traceability/GetDocumentTypesAttestation'
const URL_GETAUDIT = '/api/Traceability/GetAudit'
const URL_GETATTESTATIONS = '/api/Traceability/GetAttestations'
const URL_GETATTESTATIONSDETAIL = '/api/Traceability/GetAttestationDetail'
const URL_GETATTESTATIONSDOCUMENT = '/api/Traceability/GetAttestationDocument'
const URL_GETCONFORMITY = '/api/Traceability/GetConformity'
const URL_ADDCONFORMITY = '/api/Traceability/AddConformity'
const URL_DELETECONFORMITY = '/api/Traceability/DeleteConformity'
const URL_DOWNLOADCONFORMITY = '/api/Traceability/DownloadConformity'

const TraceabilityService = {
  getDocumentTypesAudit(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETDOCUMENTTYPESAUDIT, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDocumentTypesAttestation(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETDOCUMENTTYPESATTESTATION, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAudit(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETAUDIT, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAttestations(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETATTESTATIONS, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAttestationsDetail(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETATTESTATIONSDETAIL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAttestationsDocument(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETATTESTATIONSDOCUMENT, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getConformity(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETCONFORMITY, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addConformity(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_ADDCONFORMITY, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteConformity(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_DELETECONFORMITY, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  downloadConformity(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_DOWNLOADCONFORMITY, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default TraceabilityService
