import ApiService from '../services/ApiService'

const URL_GETSTATISTICS = '/api/Statistics/GetStatistics'
const URL_GETDOCUMENTTYPES = '/api/Statistics/GetDocumentTypes'

const StatisticsService = {
  getStatistics(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETSTATISTICS, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDocumentTypes(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETDOCUMENTTYPES, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default StatisticsService
