<template>
  <v-container fluid class="grey lighten-2 flexContainer">
    <v-card class="w-full h-full d-flex flex-column" rounded="0">
      <v-card-title class="pa-1 pl-2 secondary">
        <slot name="title">
          <v-btn
            x-small
            fab
            dark
            rounded
            :disabled="!valid"
            color="secondarytext"
            elevation="1"
            class="mr-2 mt-1 mb-1"
            @click="getAcl"
          >
            <v-icon class="secondary--text"> mdi-autorenew </v-icon>
          </v-btn>
          <span class="font-weight-medium text-h6 secondarytext--text">
            {{ $t('Navigation.admin_children.' + $route.name) }}
          </span>
        </slot>
      </v-card-title>
      <v-divider />
      <v-row style="flex: initial; flex-shrink: 0" class="ma-0 pt-0 pb-0">
        <div class="white pb-4 pa-2 pl-4 pr-4 filterRow">
          <div class="filters">
            <v-select
              v-model="documentType"
              hide-details
              :debounce-search="0"
              :clearable="false"
              :items="documentTypes"
              :label="$t('AuthorizationAcl.docType')"
              class="text-caption mr-2"
              @change="onDocTypeChange"
            />
          </div>
          <v-switch
            v-model="maintenanceMode"
            class="mr-2 pb-1"
            hide-details
            :loading="loading || waitingForUnlock"
            :disabled="!valid || loading || waitingForUnlock"
            inset
            :label="$t('AuthorizationAcl.maintenanceMode')"
            @change="switchLockState"
          />
          <v-btn
            required
            :disabled="!valid || !maintenanceMode"
            :loading="loading"
            @click="showAddAclDialog"
          >
            <v-icon left color="primary"> ci-plus </v-icon>
            {{ $t('AuthorizationAcl.addAcl') }}
          </v-btn>
        </div>
      </v-row>
      <v-row class="ma-0 pb-0 pt-0" style="overflow: auto">
        <argus-grid
          ref="argusGrid"
          :items="items"
          :total-results="totalResults"
          :hide-footer="true"
          :headers="headers"
          :load-data="getAcl"
        >
          <template slot="renderer.Issuer" slot-scope="{ item }">
            {{ item.Issuer.IssuerName }}
          </template>
          <template slot="renderer.Role" slot-scope="{ item }">
            {{ item.Role.RoleName }}
          </template>
          <template slot="renderer.IsGroup" slot-scope="{ item }">
            <span v-if="item.IsGroup == false">
              {{ $t('AuthorizationAcl.user') }}
            </span>
            <span v-else>
              {{ $t('AuthorizationAcl.group') }}
            </span>
          </template>
          <template slot="renderer.Actions" slot-scope="{ item }">
            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="primary"
                  class="mr-2"
                  :disabled="!maintenanceMode"
                  v-on="on"
                  @click="showEditAclDialog(item)"
                >
                  ci-edit_pen
                </v-icon>
              </template>
              <span>{{ $t('AuthorizationAcl.editAcl') }}</span>
            </v-tooltip>
            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="primary"
                  :disabled="!maintenanceMode"
                  v-on="on"
                  @click="removeAcl(item)"
                >
                  ci-trash
                </v-icon>
              </template>
              <span>{{ $t('AuthorizationAcl.deleteAcl') }}</span>
            </v-tooltip>
          </template>
        </argus-grid>
      </v-row>
    </v-card>

    <v-dialog v-model="addAclDialog" width="500" transition="fade-transition">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            dark
            color="primary"
            class="text-center primarytext--text"
            style="display: block"
          >
            {{ $t('AuthorizationAcl.addAcl') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="addAclDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-radio-group v-model="selectedType" mandatory dense row>
            <v-radio :label="$t('AuthorizationAcl.user')" value="User" />
            <v-radio :label="$t('AuthorizationAcl.group')" value="Group" />
          </v-radio-group>
          <v-select
            v-model="selectedIssuer"
            hide-details
            :debounce-search="0"
            :clearable="false"
            :items="issuers"
            :label="$t('AuthorizationAcl.issuer')"
            item-text="IssuerName"
            item-value="IssuerName"
            class="text-caption"
            @change="onIssuerChange"
          />
          <v-select
            v-model="selectedRole"
            hide-details
            :debounce-search="0"
            :clearable="false"
            :items="roles"
            :label="$t('AuthorizationAcl.role')"
            item-text="RoleName"
            item-value="RoleName"
            class="text-caption"
          />
          <v-select
            v-if="selectedIssuer != undefined && selectedType == 'Group'"
            v-model="selectedGroup"
            hide-details
            :debounce-search="0"
            :clearable="false"
            :items="groups"
            :label="$t('AuthorizationAcl.group')"
            class="text-caption"
          />
          <v-select
            v-if="selectedIssuer != undefined && selectedType == 'User'"
            v-model="selectedUser"
            hide-details
            :debounce-search="0"
            :clearable="false"
            :items="users"
            :label="$t('AuthorizationAcl.user')"
            item-text="UserName"
            item-value="UserName"
            class="text-caption"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            required
            :disabled="!addAclIsValid"
            :loading="loading"
            color="success"
            class="mt-2 mr-3 mb-2"
            @click="addAcl"
          >
            <v-icon left> ci-plus </v-icon>
            {{ $t('AuthorizationAcl.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editAclDialog" width="500" transition="fade-transition">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            dark
            color="primary"
            class="text-center primarytext--text"
            style="display: block"
          >
            {{ $t('AuthorizationAcl.editAcl') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="editAclDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-radio-group v-model="selectedType" mandatory dense row disabled>
            <v-radio :label="$t('AuthorizationAcl.user')" value="User" />
            <v-radio :label="$t('AuthorizationAcl.group')" value="Group" />
          </v-radio-group>
          <v-select
            v-model="selectedIssuer"
            hide-details
            disabled
            :debounce-search="0"
            :clearable="false"
            :items="issuers"
            :label="$t('AuthorizationAcl.issuer')"
            item-text="IssuerName"
            item-value="IssuerName"
            class="text-caption"
            @change="onIssuerChange"
          />
          <v-select
            v-model="selectedRole"
            hide-details
            :debounce-search="0"
            :clearable="false"
            :items="roles"
            :label="$t('AuthorizationAcl.role')"
            item-text="RoleName"
            item-value="RoleName"
            class="text-caption"
          />
          <v-select
            v-if="selectedIssuer != undefined && selectedType == 'Group'"
            v-model="selectedGroup"
            hide-details
            disabled
            :debounce-search="0"
            :clearable="false"
            :items="groups"
            :label="$t('AuthorizationAcl.group')"
            class="text-caption"
          />
          <v-select
            v-if="selectedIssuer != undefined && selectedType == 'User'"
            v-model="selectedUser"
            hide-details
            disabled
            :debounce-search="0"
            :clearable="false"
            :items="users"
            :label="$t('AuthorizationAcl.user')"
            item-text="UserName"
            item-value="UserName"
            class="text-caption"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            required
            :disabled="!addAclIsValid"
            :loading="loading"
            color="success"
            class="mt-2 mr-3 mb-2"
            @click="editAcl"
          >
            <v-icon left> ci-plus </v-icon>
            {{ $t('AuthorizationAcl.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
      transition="fade-transition"
    >
      <v-card color="primary" dark>
        <v-card-title
          class="text-center primarytext--text"
          style="display: block"
        >
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <Confirm ref="confirmDelete" />
  </v-container>
</template>

<script>
import AuthorizationService from '../services/AuthorizationService'

export default {
  components: {
    ArgusGrid: () => import('../components/ArgusGrid'),
    Confirm: () => import('../components/Confirm'),
  },
  data() {
    return {
      items: [],
      totalResults: 0,
      documentType: '',
      documentTypes: [],
      types: [
        {
          Display: this.$t('AuthorizationAcl.group'),
          Value: 'Group',
        },
        {
          Display: this.$t('AuthorizationAcl.user'),
          Value: 'User',
        },
      ],
      issuers: [],
      roles: [],
      groups: [],
      users: [],
      selectedType: 'User',
      selectedUser: undefined,
      selectedGroup: undefined,
      selectedRole: undefined,
      selectedIssuer: undefined,
      addAclDialog: false,
      editAclDialog: false,
      loading: false,
      maintenanceMode: false,
      waitingForUnlock: false,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('AuthorizationAcl.name'),
          value: 'Name',
          sortable: false,
        },
        {
          text: this.$t('AuthorizationAcl.issuer'),
          value: 'Issuer',
          sortable: false,
          renderer: true,
        },
        {
          text: this.$t('AuthorizationAcl.isGroup'),
          value: 'IsGroup',
          sortable: false,
          renderer: true,
        },
        {
          text: this.$t('AuthorizationAcl.role'),
          value: 'Role',
          sortable: false,
          renderer: true,
        },
        {
          text: this.$t('AuthorizationAcl.actions'),
          value: 'Actions',
          sortable: false,
          renderer: true,
          align: 'center',
          width: '150px',
        },
      ]
    },
    valid() {
      return this.documentType != ''
    },
    addAclIsValid() {
      if (this.selectedIssuer == undefined || this.selectedRole == undefined) {
        return false
      } else {
        if (this.selectedType == 'User') {
          if (this.selectedUser == undefined) {
            return false
          } else {
            return true
          }
        } else {
          if (this.selectedGroup == undefined) {
            return false
          } else {
            return true
          }
        }
      }
    },
  },
  mounted() {
    this.getDocumentTypes()

    setInterval(this.checkLockState, 10000)
  },
  methods: {
    getAcl() {
      if (this.valid) {
        let inst = this
        let request = {}

        inst.loading = true

        request.DocumentType = this.documentType

        AuthorizationService.getACL(request)
          .then((resp) => {
            inst.totalResults = resp.data.data.length
            inst.items = resp.data.data
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    showAddAclDialog() {
      this.selectedIssuer = undefined
      this.selectedGroup = undefined
      this.selectedUser = undefined
      this.selectedRole = undefined
      this.selectedType = 'Group'
      this.addAclDialog = true
    },
    showEditAclDialog(item) {
      this.originalItem = item
      this.selectedIssuer = item.Issuer.IssuerName
      this.selectedGroup = undefined
      this.selectedUser = undefined
      this.selectedRole = item.Role.RoleName

      let inst = this

      this.onIssuerChange().then(function () {
        if (item.IsGroup) {
          inst.selectedType = 'Group'
          inst.selectedGroup = item.Name
        } else {
          inst.selectedType = 'User'
          inst.selectedUser = item.Name
        }
        inst.editAclDialog = true
      })
    },
    onDocTypeChange() {
      let inst = this
      inst.getLockState().then(function () {
        inst.getRoles().then(function () {
          inst.getAcl()
        })
      })
    },
    onIssuerChange() {
      return new Promise((resolve, reject) => {
        this.loading = true
        let inst = this

        let request = {
          IssuerName: this.selectedIssuer,
          DocumentType: this.documentType,
          PageSize: 1000,
          PageIndex: 1,
        }

        AuthorizationService.getUsersACL(request)
          .then((resp) => {
            inst.users = resp.data.data

            AuthorizationService.getGroupsACL(request).then((resp) => {
              inst.groups = resp.data
              resolve(resp)
            })
          })
          .catch((err) => {
            reject(err)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    addAcl() {
      let request = {}
      let acl = {}
      let inst = this

      this.loading = true

      request.DocumentType = this.documentType
      request.ACL = []

      acl.IsGroup = this.selectedType == 'Group'
      if (acl.IsGroup) {
        acl.Name = this.selectedGroup
      } else {
        acl.Name = this.selectedUser
      }
      acl.Role = this.roles.filter((q) => q.RoleName == this.selectedRole)[0]
      acl.Issuer = this.issuers.filter(
        (q) => q.IssuerName == this.selectedIssuer
      )[0]

      request.ACL.push(acl)

      AuthorizationService.setACL(request)
        .then(function () {
          inst.getAcl()
          inst.addAclDialog = false
        })
        .finally(function () {
          inst.loading = false
        })
    },
    editAcl() {
      let request = {}
      let inst = this

      this.loading = true

      request.DocumentType = this.documentType
      request.ACLProperties = this.originalItem
      request.NewRole = this.selectedRole

      AuthorizationService.updateACL(request)
        .then(function () {
          inst.getAcl()
          inst.editAclDialog = false
        })
        .finally(function () {
          inst.loading = false
        })
    },
    removeAcl(item) {
      this.$refs.confirmDelete
        .open(
          this.$t('AuthorizationAcl.deleteAcl'),
          this.$t('Global.areYouSure'),
          {
            color: 'red',
          }
        )
        .then((res) => {
          if (res.result) {
            this.loading = true
            let inst = this
            let request = {
              ACL: [item],
              DocumentType: this.documentType,
            }

            AuthorizationService.removeACL(request)
              .then(function () {
                inst.getAcl()
              })
              .finally(function () {
                inst.loading = false
              })
          }
        })
    },
    getRoles() {
      return new Promise((resolve, reject) => {
        this.loading = true
        let inst = this

        if (
          this.documentType == '' ||
          this.documentType == null ||
          this.documentType == undefined
        ) {
          this.roles = []
          this.issuers = []
        } else {
          AuthorizationService.getRolesAndIssuers({
            DocumentType: this.documentType,
          })
            .then((resp) => {
              inst.roles = resp.data.Roles
              inst.issuers = resp.data.Issuers
              resolve(resp)
            })
            .catch((err) => {
              reject(err)
            })
            .finally(function () {
              inst.loading = false
            })
        }
      })
    },
    getLockState() {
      return new Promise((resolve, reject) => {
        this.loading = true
        let inst = this

        AuthorizationService.getLockState({
          DocumentType: this.documentType,
        })
          .then((resp) => {
            switch (resp.data) {
              case 0:
                inst.maintenanceMode = false
                inst.waitingForUnlock = false
                break
              case 1:
              case 4:
                inst.maintenanceMode = true
                inst.waitingForUnlock = false
                break
              case 2:
                inst.maintenanceMode = true
                inst.waitingForUnlock = true
                break
            }
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    switchLockState() {
      this.loading = true
      let inst = this

      if (this.maintenanceMode) {
        AuthorizationService.enableMaintenanceMode({
          DocumentType: this.documentType,
        })
          .then(function () {
            inst.maintenanceMode = true
          })
          .finally(function () {
            inst.loading = false
          })
      } else {
        AuthorizationService.disableMaintenanceMode({
          DocumentType: this.documentType,
        })
          .then(function () {
            inst.maintenanceMode = true
            inst.waitingForUnlock = true
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    checkLockState() {
      if (this.waitingForUnlock && this.valid) {
        this.getLockState()
      }
    },
    getDocumentTypes() {
      this.loading = true
      let inst = this

      AuthorizationService.getDocumentTypesACL()
        .then((resp) => {
          inst.documentTypes = resp.data
          inst.documentTypes = inst.documentTypes.sort()
          if (inst.documentTypes.length > 0) {
            inst.documentType = inst.documentTypes[0]
            inst.onDocTypeChange()
          }
        })
        .finally(function () {
          inst.loading = false
        })
    },
  },
}
</script>
