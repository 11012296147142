import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import Ripple from 'vuetify/lib/directives/ripple'
import fr from 'vuetify/lib/locale/en'
import en from 'vuetify/lib/locale/en'
import nl from 'vuetify/lib/locale/nl'

import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { en, fr, nl },
    current: 'en',
  },
})
