<template>
  <v-container fluid class="grey lighten-2 flexContainer">
    <v-card class="w-full h-full d-flex flex-column" rounded="0">
      <v-card-title class="pa-1 pl-2 secondary">
        <slot name="title">
          <v-btn
            x-small
            fab
            dark
            rounded
            :disabled="!valid"
            color="secondarytext"
            elevation="1"
            class="mr-2 mt-1 mb-1"
            @click="prepareQuery"
          >
            <v-icon class="secondary--text"> mdi-autorenew </v-icon>
          </v-btn>
          <span class="font-weight-medium text-h6 secondarytext--text">
            {{ $t('Navigation.admin_children.' + $route.name) }}
          </span>
        </slot>
      </v-card-title>
      <v-divider />
      <v-row style="flex: initial; flex-shrink: 0" class="ma-0 pt-0 pb-0">
        <div class="white pb-4 pa-2 pl-4 pr-4 filterRow">
          <div class="filters">
            <v-select
              v-model="documentType"
              hide-details
              :debounce-search="0"
              :clearable="false"
              :items="documentTypes"
              :label="$t('TraceabilityAudit.docType')"
              item-text="text"
              item-value="value"
              class="text-caption mr-2"
              width="calc(100% / 6)"
              @change="prepareQuery"
            />
            <v-menu
              v-model="selectDateFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDateFrom"
                  hide-details
                  data-type="date"
                  class="text-caption mr-2"
                  readonly
                  v-bind="attrs"
                  :clearable="true"
                  :label="$t('TraceabilityAttestations.dateFrom')"
                  append-icon="ci-calendar_from"
                  v-on="on"
                  @click:clear="clearDateFrom"
                />
              </template>
              <v-date-picker
                v-model="dateFrom"
                @input="selectDateFrom = false"
                @change="checkDates"
              />
            </v-menu>
            <v-menu
              v-model="selectDateTo"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDateTo"
                  hide-details
                  data-type="date"
                  class="text-caption mr-2"
                  readonly
                  v-bind="attrs"
                  :clearable="true"
                  :label="$t('TraceabilityAttestations.dateTo')"
                  append-icon="ci-calendar_to"
                  v-on="on"
                  @click:clear="clearDateTo"
                />
              </template>
              <v-date-picker
                v-model="dateTo"
                @input="selectDateTo = false"
                @change="checkDates"
              />
            </v-menu>
            <v-text-field
              v-model="action"
              hide-details
              :label="$t('TraceabilityAttestations.action')"
              :clearable="true"
              class="text-caption mr-2"
              @click:clear="clearAction"
              @keydown.enter="prepareQuery"
            />
            <v-text-field
              v-model="ticket"
              hide-details
              :label="$t('TraceabilityAttestations.ticket')"
              :clearable="true"
              class="text-caption mr-2"
              @click:clear="clearTicket"
              @keydown.enter="prepareQuery"
            />
          </div>

          <v-btn
            required
            :disabled="!valid"
            :loading="loading"
            @click="prepareQuery"
          >
            <v-icon left color="primary"> mdi-magnify </v-icon>
            {{ $t('TraceabilityAttestations.search') }}
          </v-btn>
        </div>
      </v-row>
      <v-row class="ma-0 pb-0 pt-0" style="overflow: auto">
        <argus-grid
          ref="argusGrid"
          :items="items"
          :headers="headers"
          :load-data="getAttestations"
          :total-results="totalResults"
          :loading="loading"
        >
          <template slot="renderer.Info" slot-scope="{ item }">
            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  @click="showAttestationInfo(item)"
                  v-on="on"
                >
                  <v-icon>ci-eye</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TraceabilityAttestations.show') }}</span>
            </v-tooltip>

            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  @click="getAttestationsDocument(item)"
                  v-on="on"
                >
                  <v-icon>ci-download_legal</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TraceabilityAttestations.download') }}</span>
            </v-tooltip>
          </template>
          <template slot="renderer.Date" slot-scope="{ item }">
            {{
              moment(item.Date, 'YYYY-MM-DDThh:mm:ss[Z]').format('DD/MM/YYYY ')
            }}
          </template>
        </argus-grid>
      </v-row>
    </v-card>

    <v-dialog
      v-model="attestationDialog"
      width="500"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            dark
            color="primary"
            class="text-center primarytext--text"
            style="display: block"
          >
            {{ $t('TraceabilityAttestations.info') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="attestationDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 fixedHeight">
          <div
            v-for="(i, index) in attestationItem.filter((q) => q.value != null)"
            :key="i.label"
          >
            <label class="font-weight-medium mr-8 grey--text text--darken-4">{{
              i.label
            }}</label>
            <v-spacer />
            <vue-json-pretty
              v-if="typeof i.value === 'object'"
              :data="i.value"
              :show-double-quotes="false"
            />
            <span
              v-if="typeof i.value !== 'object' && i.value.indexOf('</') != -1"
              style="white-space: pre-wrap"
            >
              {{ getFormattedXml(i.value) }}
            </span>
            <span
              v-if="typeof i.value !== 'object' && i.value.indexOf('</') == -1"
              >{{ i.value }}</span
            >
            <v-divider
              v-if="
                index + 1 !=
                attestationItem.filter((q) => q.value != null).length
              "
              class="mt-1 mb-1"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
      transition="fade-transition"
    >
      <v-card color="primary" dark>
        <v-card-title
          class="text-center primarytext--text"
          style="display: block"
        >
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TraceabilityService from '../services/TraceabilityService'
import moment from 'moment'
import xmlformat from 'xml-formatter'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    ArgusGrid: () => import('../components/ArgusGrid'),
    VueJsonPretty: () => import('vue-json-pretty'),
  },
  data() {
    return {
      items: [],
      documentType: '',
      documentTypes: [],
      action: '',
      ticket: '',
      dateFrom: '',
      dateTo: '',
      attestationItem: [],
      selectDateFrom: false,
      selectDateTo: false,
      loading: false,
      attestationDialog: false,
      totalResults: 0,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('TraceabilityAttestations.info'),
          value: 'Info',
          sortable: false,
          renderer: true,
          align: 'center',
          width: '110px',
        },
        {
          text: this.$t('TraceabilityAttestations.date'),
          value: 'Date',
          sortable: false,
          renderer: true,
          width: '100px',
        },
        {
          text: this.$t('TraceabilityAttestations.action'),
          value: 'Action',
          sortable: false,
        },
        {
          text: this.$t('TraceabilityAttestations.attestationTicket'),
          value: 'AttestationTicket',
          sortable: false,
        },
        {
          text: this.$t('TraceabilityAttestations.dataTicket'),
          value: 'DataTicket',
          sortable: false,
        },
        {
          text: this.$t('TraceabilityAttestations.rootTicket'),
          value: 'RootTicket',
          sortable: false,
        },
      ]
    },
    valid() {
      return this.documentType != ''
    },
    formattedDateFrom() {
      if (this.dateFrom == '') {
        return ''
      } else {
        return moment(this.dateFrom, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    },
    formattedDateTo() {
      if (this.dateTo == '') {
        return ''
      } else {
        return moment(this.dateTo, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    },
  },
  created() {
    this.getDocumentTypes()
  },
  methods: {
    getFormattedXml(string) {
      return xmlformat('<xml>' + string + '</xml>')
    },
    checkDates() {
      if (
        this.dateFrom != '' &&
        this.dateTo != '' &&
        moment(this.dateFrom).isAfter(this.dateTo)
      ) {
        this.dateFrom = this.dateTo
      }
    },
    prepareQuery() {
      if (this.$refs.argusGrid) {
        if (this.$refs.argusGrid.options.page == 1) {
          this.getAttestations()
        } else {
          this.$refs.argusGrid.setPage(1)
        }
      }
    },
    clearDateFrom() {
      this.dateFrom = ''
      this.prepareQuery()
    },
    clearDateTo() {
      this.dateTo = ''
      this.prepareQuery()
    },
    clearAction() {
      this.action = ''
      this.prepareQuery()
    },
    clearTicket() {
      this.ticket = ''
      this.prepareQuery()
    },
    getDocumentTypes() {
      this.loading = true
      let inst = this

      TraceabilityService.getDocumentTypesAttestation()
        .then((resp) => {
          inst.documentTypes = resp.data
          inst.documentTypes = inst.documentTypes.sort()
          if (inst.documentTypes.length > 0) {
            inst.documentType = inst.documentTypes[0]
            inst.prepareQuery()
          }
        })
        .finally(function () {
          inst.loading = false
        })
    },
    getAttestations() {
      if (this.valid) {
        let inst = this
        let request = {}

        inst.loading = true

        request.DocumentType = this.documentType
        request.PageIndex = this.$refs.argusGrid.options.page
        request.PageSize = this.$refs.argusGrid.options.itemsPerPage
        request.Filter = {
          Ticket: this.ticket,
          Action: this.action,
          From: this.dateFrom,
          To: this.dateTo,
        }

        TraceabilityService.getAttestations(request)
          .then((resp) => {
            inst.totalResults = resp.data.itemsCount
            inst.items = resp.data.data

            if (request.Filter.Ticket && request.Filter.Ticket != '') {
              inst.totalResults = inst.items.length
            }
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    showAttestationInfo(item) {
      this.loading = true
      this.attestationItem = []
      let inst = this
      let request = {
        RecordRef: item.RecordRef,
        DocumentType: inst.documentType,
        Domain: this.$store.getters.siteConfig.DOMAIN,
      }

      TraceabilityService.getAttestationsDetail(request)
        .then((resp) => {
          Object.keys(resp.data).forEach((e) =>
            inst.attestationItem.push({ label: e, value: resp.data[e] })
          )
          inst.attestationDialog = true
        })
        .finally(function () {
          inst.loading = false
        })
    },
    base64ToArrayBuffer(data) {
      let binaryString = window.atob(data)
      let binaryLen = binaryString.length
      let bytes = new Uint8Array(binaryLen)
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    getAttestationsDocument(item) {
      this.loading = true
      let inst = this
      let request = {
        RecordRef: item.RecordRef,
        DocumentType: inst.documentType,
        Domain: this.$store.getters.siteConfig.DOMAIN,
        AttestationTicket: item.AttestationTicket,
      }

      TraceabilityService.getAttestationsDocument(request)
        .then((resp) => {
          let saveAs = require('file-saver')
          let buffer = this.base64ToArrayBuffer(resp.data.Base64)
          let blob = new Blob([buffer], { type: resp.data.MimeType })
          saveAs(blob, resp.data.FileName)
        })
        .finally(function () {
          inst.loading = false
        })
    },
  },
}
</script>
