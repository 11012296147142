<template>
  <v-container fluid class="grey lighten-2 flexContainer">
    <v-card class="w-full h-full d-flex flex-column" rounded="0">
      <v-card-title class="pa-1 pl-2 secondary">
        <slot name="title">
          <v-btn
            x-small
            fab
            dark
            rounded
            :disabled="!valid"
            color="secondarytext"
            elevation="1"
            class="mr-2 mt-1 mb-1"
            @click="getConformity"
          >
            <v-icon class="secondary--text"> mdi-autorenew </v-icon>
          </v-btn>
          <span class="font-weight-medium text-h6 secondarytext--text">
            {{ $t('Navigation.admin_children.' + $route.name) }}
          </span>
        </slot>
      </v-card-title>
      <v-divider />
      <v-row style="flex: initial; flex-shrink: 0" class="ma-0 pt-0 pb-0">
        <div class="white pb-4 pa-2 pl-4 pr-4 filterRow">
          <div class="filters">
            <v-select
              v-model="documentType"
              hide-details
              :debounce-search="0"
              :clearable="false"
              :items="documentTypes"
              :label="$t('TraceabilityConformity.docType')"
              item-text="text"
              item-value="value"
              class="text-caption mr-2"
              @change="getConformity"
            />
          </div>
          <v-btn
            :loading="loading"
            :disabled="!valid"
            @click="showAddConformityDialog"
          >
            <v-icon left color="primary"> mdi-plus </v-icon>
            {{ $t('TraceabilityConformity.addRequest') }}
          </v-btn>
        </div>
      </v-row>
      <v-row class="ma-0 pb-0 pt-0" style="overflow: auto">
        <argus-grid
          ref="argusGrid"
          :items="items"
          :total-results="totalResults"
          :hide-footer="true"
          :headers="headers"
          :load-data="getConformity"
        >
          <template slot="renderer.RequestDate" slot-scope="{ item }">
            {{
              moment(item.RequestDate, 'YYYY-MM-DDTHH:mm:ss.fff').format(
                'DD/MM/YYYY HH:mm:ss'
              )
            }}
          </template>
          <template slot="renderer.Info" slot-scope="{ item }">
            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="item.State != 3"
                  color="primary"
                  :loading="item.State == 1"
                  :disabled="item.State == 1"
                  icon
                  v-bind="attrs"
                  @click="downloadConformity(item)"
                  v-on="on"
                >
                  <v-icon>ci-download</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TraceabilityConformity.download') }}</span>
            </v-tooltip>

            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="item.State == 3"
                  color="red"
                  icon
                  v-bind="attrs"
                  @click="downloadConformity(item)"
                  v-on="on"
                >
                  <v-icon>ci-warning</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TraceabilityConformity.download') }}</span>
            </v-tooltip>

            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  @click="deleteConformity(item)"
                  v-on="on"
                >
                  <v-icon>ci-trash</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('TraceabilityConformity.deleteRequest') }}</span>
            </v-tooltip>
          </template>
        </argus-grid>
      </v-row>
    </v-card>

    <v-dialog
      v-model="conformityDialog"
      width="500"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            dark
            color="primary"
            class="text-center primarytext--text"
            style="display: block"
          >
            {{ $t('TraceabilityConformity.addRequest') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="conformityDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            v-model="documentTicket"
            hide-details
            :label="$t('TraceabilityConformity.ticket')"
            :clearable="true"
            class="text-caption"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            required
            :disabled="!addConformityIsValid"
            :loading="loading"
            color="primary"
            class="mr-3 mb-2"
            @click="addConformity"
          >
            <v-icon left> mdi-check </v-icon>
            {{ $t('TraceabilityConformity.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
      transition="fade-transition"
    >
      <v-card color="primary" dark>
        <v-card-title
          class="text-center primarytext--text"
          style="display: block"
        >
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <Confirm ref="confirmDelete" />
  </v-container>
</template>

<script>
import TraceabilityService from '../services/TraceabilityService'

export default {
  components: {
    ArgusGrid: () => import('../components/ArgusGrid'),
    Confirm: () => import('../components/Confirm'),
  },
  data() {
    return {
      items: [],
      totalResults: 0,
      documentTypes: [],
      documentType: '',
      documentTicket: '',
      conformityDialog: false,
      loading: false,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('TraceabilityConformity.info'),
          value: 'Info',
          sortable: false,
          renderer: true,
          align: 'center',
          width: '150px',
        },
        {
          text: this.$t('TraceabilityConformity.date'),
          value: 'RequestDate',
          sortable: false,
          renderer: true,
          width: '220px',
        },
        {
          text: this.$t('TraceabilityConformity.ticket'),
          value: 'DocumentTicket',
          sortable: false,
        },
      ]
    },
    valid() {
      return this.documentType != ''
    },
    addConformityIsValid() {
      return this.documentTicket != '' && this.document != ''
    },
  },
  mounted() {
    setInterval(this.checkConformity, 10000)

    this.getDocumentTypes()
  },
  methods: {
    getConformity() {
      if (this.valid) {
        let inst = this
        let request = {}

        inst.loading = true

        request.DocumentType = this.documentType

        TraceabilityService.getConformity(request)
          .then((resp) => {
            inst.totalResults = resp.data.itemsCount
            inst.items = resp.data.data
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    getConformityWithoutLoader() {
      if (this.valid) {
        let inst = this
        let request = {}

        request.DocumentType = this.documentType

        TraceabilityService.getConformity(request).then((resp) => {
          inst.totalResults = resp.data.itemsCount
          inst.items = resp.data.data
        })
      }
    },
    getDocumentTypes() {
      this.loading = true
      let inst = this

      return new Promise((resolve, reject) => {
        TraceabilityService.getDocumentTypesAttestation()
          .then((resp) => {
            inst.documentTypes = resp.data
            inst.documentTypes = inst.documentTypes.sort()
            if (inst.documentTypes.length > 0) {
              inst.documentType = inst.documentTypes[0]
              inst.getConformity()
            }
            resolve()
          })
          .catch(function (error) {
            inst.loading = false
            reject(error)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    showAddConformityDialog() {
      this.documentTicket = ''
      this.conformityDialog = true
    },
    addConformity() {
      this.loading = true
      let inst = this

      let request = {
        DocumentTicket: this.documentTicket,
        DocumentType: this.documentType,
      }

      TraceabilityService.addConformity(request)
        .then(function () {
          inst.conformityDialog = false
          inst.getConformity()
        })
        .finally(function () {
          inst.loading = false
        })
    },
    deleteConformity(item) {
      this.$refs.confirmDelete
        .open(
          this.$t('TraceabilityConformity.deleteRequest'),
          this.$t('Global.areYouSure'),
          {
            color: 'red',
          }
        )
        .then((res) => {
          if (res.result) {
            this.loading = true
            let inst = this

            let request = {
              RequestId: item.Id,
              DocumentType: this.documentType,
            }

            TraceabilityService.deleteConformity(request)
              .then(function () {
                inst.getConformity()
              })
              .finally(function () {
                inst.loading = false
              })
          }
        })
    },
    base64ToArrayBuffer(data) {
      let binaryString = window.atob(data)
      let binaryLen = binaryString.length
      let bytes = new Uint8Array(binaryLen)
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    downloadConformity(item) {
      this.loading = true
      let inst = this
      let request = {
        RequestId: item.Id,
        DocumentType: inst.documentType,
      }

      TraceabilityService.downloadConformity(request)
        .then((resp) => {
          let saveAs = require('file-saver')
          let buffer = this.base64ToArrayBuffer(resp.data.Base64)
          let blob = new Blob([buffer], { type: resp.data.MimeType })
          saveAs(blob, resp.data.FileName)
        })
        .finally(function () {
          inst.loading = false
        })
    },
    checkConformity() {
      if (
        !this.loading &&
        !this.conformityDialog &&
        this.items.filter((q) => q.State == 1).length > 0
      ) {
        this.getConformityWithoutLoader()
      }
    },
  },
}
</script>

<style scoped>
.fixedHeight {
  max-height: 75vh;
  overflow: auto;
}
</style>
