import ApiService from '../services/ApiService'

const URL_GETINDEXES = '/api/Authorization/GetIndexes'
const URL_GETDOCUMENTTYPESACL = '/api/Authorization/GetDocumentTypesACL'
const URL_GETDOCUMENTTYPESCONSTRAINTS =
  '/api/Authorization/GetDocumenttypesConstraints'
const URL_GETROLESANDISSUERS = '/api/Authorization/GetRolesAndIssuers'
const URL_GETCONSTRAINTS = '/api/Authorization/GetConstraints'
const URL_GETUSERSACL = '/api/Authorization/GetUsersACL'
const URL_GETGROUPSACL = '/api/Authorization/GetGroupsACL'
const URL_GETACL = '/api/Authorization/GetACL'
const URL_SETACL = '/api/Authorization/SetACL'
const URL_UPDATEACL = '/api/Authorization/UpdateACL'
const URL_REMOVEACL = '/api/Authorization/RemoveACL'
const URL_ADDCONSTRAINT = '/api/Authorization/AddConstraint'
const URL_UPDATECONSTRAINT = '/api/Authorization/UpdateConstraint'
const URL_DELETECONSTRAINT = '/api/Authorization/DeleteConstraints'
const URL_GETLOCKSTATE = '/api/Authorization/GetLockState'
const URL_ENABLEMAINTENANCEMODE = '/api/Authorization/EnableMaintenanceMode'
const URL_DISABLEMAINTENANCEMODE = '/api/Authorization/DisableMaintenanceMode'

const AuthorizationService = {
  getIndexes(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETINDEXES, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDocumentTypesACL(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETDOCUMENTTYPESACL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDocumentTypesConstraints(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETDOCUMENTTYPESCONSTRAINTS, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getRolesAndIssuers(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETROLESANDISSUERS, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getConstraints(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETCONSTRAINTS, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getUsersACL(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETUSERSACL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getGroupsACL(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETGROUPSACL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getACL(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETACL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setACL(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_SETACL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateACL(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_UPDATEACL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeACL(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_REMOVEACL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addConstraint(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_ADDCONSTRAINT, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateConstraint(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_UPDATECONSTRAINT, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteConstraint(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_DELETECONSTRAINT, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getLockState(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETLOCKSTATE, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  enableMaintenanceMode(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_ENABLEMAINTENANCEMODE, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  disableMaintenanceMode(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_DISABLEMAINTENANCEMODE, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default AuthorizationService
