import ApiService from '../services/ApiService'

const URL_ACTIVATE = '/api/Mfa/Activate'
const URL_DEACTIVATE = '/api/Mfa/Deactivate'
const URL_RECOVERYCODES = '/api/Mfa/GetRecoveryCodes'
const URL_SENDMAIL = '/api/Mfa/SendMail'
const URL_GETMFAMETHOD = '/api/Mfa/GetMfaMethod'
const URL_GETMFACONFIG = '/api/Mfa/GetMfaConfigurations'

const MfaService = {
  activate(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_ACTIVATE, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deactivate(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_DEACTIVATE, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getRecoveryCodes(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_RECOVERYCODES, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  sendMail(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_SENDMAIL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getMfaMethod(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETMFAMETHOD, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getMfaConfigurations(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETMFACONFIG, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default MfaService
