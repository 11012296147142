import axios from 'axios'
import store from '../store'

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.defaults.maxContentLength = Infinity
    axios.defaults.crossDomain = true
    axios.defaults.withCredentials = true
  },

  setHeader(token) {
    if (token) {
      axios.defaults.headers.common['Authorization'] =
        'ARGUS ' + encodeURIComponent(token)
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }
  },

  setLanguage() {
    axios.defaults.headers.common['Language'] = store.getters.lang
  },

  removeHeader() {
    axios.defaults.headers.common = {}
  },

  get(resource, config) {
    this.setLanguage()
    return axios.get(resource, config)
  },

  post(resource, data, config) {
    this.setLanguage()
    return axios.post(resource, data, config)
  },

  put(resource, data, config) {
    this.setLanguage()
    return axios.put(resource, data, config)
  },

  delete(resource, config) {
    this.setLanguage()
    return axios.delete(resource, config)
  },

  download(url, config) {
    this.setLanguage()
    return axios.get(url, config)
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    this.setLanguage()
    return axios(data)
  },
}

export default ApiService
