import Vue from 'vue'
import Toast from 'vue-toastification'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import ApiService from './services/ApiService'
import i18n from './i18n'
import CountryFlag from 'vue-country-flag'
import VueApexCharts from 'vue-apexcharts'
import titleMixin from './mixins/titleMixin'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import VueHead from 'vue-head'

import 'vue-toastification/dist/index.css'
import 'typeface-roboto/index.css'
import 'babel-polyfill'

Vue.config.productionTip = false

Vue.prototype.$http = Axios

Vue.mixin(titleMixin)

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.prototype.moment = moment

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
})

Vue.use(VueHead)

VueClipboard.config.autoSetContainer = true

Vue.use(VueClipboard)

Vue.component('country-flag', CountryFlag)

const getRuntimeConfig = async () => {
  const runtimeConfig = await fetch(
    'config/runtimeConfig.json?v=' + Math.floor(Date.now() / 1000)
  )
  return await runtimeConfig.json()
}

getRuntimeConfig().then(function (json) {
  store.dispatch('setSiteConfig', json)

  // Set the base URL of the API
  ApiService.init(json.BASEURL_EAS)

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    CountryFlag,
    VueClipboard,
    VueHead,
    render: (h) => h(App),
  }).$mount('#app')
})
