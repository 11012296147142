<template>
  <v-row class="mfa">
    <v-col v-if="mode == modes.CHOICE" cols="12">
      <div class="secondary--text text-h5">
        {{ $t('MFA.choice.title') }}
      </div>
      <v-col class="pa-0" cols="12">
        <div class="d-flex justify-center align-center">
          <img :src="svgActivation" class="mt-8" alt="activation" />
        </div>
        <div
          v-if="step == 0"
          class="subheading font-weight-bold mt-6 grey--text text--darken-4"
        >
          {{ $t('MFA.choice.paragraph1') }}
        </div>
        <div v-if="step == 0" class="mt-2 black--text">
          {{ $t('MFA.choice.paragraph2') }}
        </div>
        <div
          v-if="step == 1"
          class="mt-4 black--text text-center font-weight-bold"
        >
          {{ $t('MFA.choice.confirmation') }}
        </div>
        <div v-if="step == 0" class="mt-2 black--text">
          <v-switch
            v-model="optedIn"
            class="mfa-switch"
            :label="$t('MFA.choice.switch')"
          />
        </div>
        <div v-if="step == 0" class="d-flex justify-center align-center mt-8">
          <v-btn
            color="secondary"
            class="secondarytext--text ml-4"
            @click="confirmChoice"
          >
            {{ $t('MFA.buttons.confirm') }}
          </v-btn>
        </div>
        <div v-if="step == 1" class="d-flex justify-center align-center mt-8">
          <v-btn
            color="secondary"
            class="secondarytext--text ml-4"
            @click="optOutCallback"
          >
            {{ $t('MFA.buttons.close') }}
          </v-btn>
        </div>
      </v-col>
    </v-col>
    <v-col v-if="mode == modes.CONNECTION" cols="12">
      <div class="secondary--text text-h5">
        {{ $t('MFA.connection.title') }}
      </div>
      <div
        v-if="connectionType == types.TOTP"
        class="subheading font-weight-medium mt-4 grey--text text--darken-4"
      >
        {{ $t('MFA.connection.subtitle-totp') }}
      </div>
      <div
        v-if="connectionType == types.HOTP"
        class="subheading font-weight-medium mt-4 grey--text text--darken-4"
      >
        {{ $t('MFA.connection.subtitle-hotp') }}
      </div>
      <div class="mt-4 text-center">
        <a
          v-if="connectionType == types.HOTP"
          :disabled="loading"
          class="text-decoration-underline text-caption"
          @click="sendMail"
          >{{ $t('MFA.connection.resend-hotp') }}</a
        >
      </div>
      <div class="mt-11">
        <v-otp-input
          v-model="otp"
          length="6"
          type="number"
          @finish="validate"
        />
      </div>
      <div class="mt-14 d-flex justify-center align-center">
        <v-btn
          required
          :disabled="!otp || otp.length != 6"
          color="secondary"
          class="secondarytext--text"
          @click="validate"
        >
          {{ $t('MFA.buttons.connect') }}
        </v-btn>
      </div>
      <div
        class="caption primary--text text-decoration-underline mt-5 m-0 text-center"
      >
        <a v-if="connectionType == types.TOTP" @click="switchToRecovery">{{
          $t('MFA.connection.recoveryLink-totp')
        }}</a>
        <a v-if="connectionType == types.HOTP" @click="switchToRecovery">{{
          $t('MFA.connection.recoveryLink-hotp')
        }}</a>
      </div>
    </v-col>
    <v-col v-if="mode == modes.ACTIVATION" cols="12">
      <div class="secondary--text text-h5">
        {{ $t('MFA.activation.title') }}
      </div>
      <div class="subheading font-weight-medium grey--text text--darken-4">
        {{ $t('MFA.activation.subtitle') }}
      </div>
      <v-col v-if="step == 0" class="pa-0" cols="12">
        <div class="d-flex justify-center align-center">
          <img :src="svgActivation" class="mt-8" alt="activation" />
        </div>
        <div
          class="subheading font-weight-bold text-center mt-6 grey--text text--darken-4"
        >
          {{ $t('MFA.activation.step0.paragraph1') }}
        </div>
        <div class="mt-2 black--text">
          {{ $t('MFA.activation.step0.paragraph2') }}
        </div>
        <div class="d-flex justify-center align-center mt-8">
          <v-btn class="mr-4" @click="cancel">
            {{ $t('MFA.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="secondary"
            class="secondarytext--text ml-4"
            @click="checkMfaConfigForActivation"
          >
            {{ $t('MFA.buttons.continue') }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="step == 1" cols="12" class="pa-0">
        <div class="subheading font-weight-bold mt-6">
          {{ $t('MFA.activation.step1.title') }}
        </div>
        <div
          v-if="mfaConfig.Totp"
          class="d-flex justify-center align-center mt-8"
        >
          <div class="d-flex flex-column flex-grow-1">
            <v-checkbox
              v-model="checkboxTotp"
              :label="$t('MFA.activation.step1.titleTotp')"
              dense
              hide-details
            />
            <span class="font-italic"
              >{{ $t('MFA.activation.step1.paragraphTotp') }}
            </span>
          </div>
          <img :src="svgTotp" alt="totp" class="flex-shrink-0" />
        </div>
        <div
          v-if="mfaConfig.Mail"
          class="d-flex justify-center align-center mt-8"
        >
          <div class="d-flex flex-column flex-grow-1">
            <v-checkbox
              v-model="checkboxHotp"
              :label="$t('MFA.activation.step1.titleHotp')"
              dense
              hide-details
            />
            <span class="font-italic"
              >{{ $t('MFA.activation.step1.paragraphHotp') }}
            </span>
          </div>
          <img :src="svgHotp" alt="hotp" class="flex-shrink-0" />
        </div>
        <div class="d-flex justify-center align-center mt-16">
          <v-btn class="mr-4" @click="back">
            {{ $t('MFA.buttons.cancel') }}
          </v-btn>
          <v-btn
            :disabled="type == types.NONE"
            color="secondary"
            class="secondarytext--text ml-4"
            @click="selectMfaType"
          >
            {{ $t('MFA.buttons.continue') }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="step == 2 && type == types.TOTP" cols="12" class="pa-0">
        <div class="body-1 font-weight-bold mt-6 primary--text">
          {{ $t('MFA.activation.step2.totp.tite') }}
        </div>
        <div class="mt-8 black--text">
          {{ $t('MFA.activation.step2.totp.paragraph1') }}
        </div>
        <div class="d-flex justify-center align-center mt-8">
          <img
            :src="qrCodeSrc"
            alt="totp"
            class="flex-shrink-0"
            style="image-rendering: pixelated"
            width="160px"
          />
        </div>
        <div class="mt-2 black--text">
          {{ $t('MFA.activation.step2.totp.paragraph2') }}
        </div>
        <div class="d-flex justify-center align-center mt-4">
          <div class="grey lighten-3 px-4 py-1">
            <span class="black--text caption font-weight-medium">{{
              mfaSecretKey
            }}</span>
            <v-icon color="black" class="ml-2" small @click="copySecretKey">
              mdi-content-copy
            </v-icon>
          </div>
        </div>
        <div class="d-flex justify-center align-center mt-16">
          <v-btn class="mr-4" @click="back">
            {{ $t('MFA.buttons.cancel') }}
          </v-btn>
          <v-btn
            :disabled="type == types.NONE"
            color="secondary"
            class="secondarytext--text ml-4"
            @click="cont"
          >
            {{ $t('MFA.buttons.continue') }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="step == 3" cols="12" class="pa-0">
        <div class="body-1 font-weight-bold mt-6 primary--text">
          {{ $t('MFA.activation.step3.title') }}
        </div>
        <div v-if="type == types.TOTP" class="mt-4 black--text">
          {{ $t('MFA.activation.step3.subtitle-totp') }}
        </div>
        <div v-if="type == types.HOTP" class="mt-4 black--text">
          {{ $t('MFA.activation.step3.subtitle-hotp') }}
        </div>
        <div class="mt-4 text-center">
          <a
            v-if="type == types.HOTP"
            :disabled="loading"
            class="text-decoration-underline text-caption"
            @click="sendMail"
            >{{ $t('MFA.activation.step3.resend-hotp') }}</a
          >
        </div>
        <div class="mt-4">
          <v-otp-input
            v-model="otp"
            length="6"
            type="number"
            @finish="activate"
          />
        </div>
        <div class="d-flex justify-center align-center mt-16">
          <v-btn class="mr-4" @click="back">
            {{ $t('MFA.buttons.cancel') }}
          </v-btn>
          <v-btn
            required
            :disabled="!otp || otp.length != 6"
            color="secondary"
            class="secondarytext--text"
            @click="activate"
          >
            {{ $t('MFA.buttons.continue') }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="step == 4" cols="12" class="pa-0">
        <div class="body-1 font-weight-bold mt-6 primary--text">
          {{ $t('MFA.activation.step4.title') }}
        </div>
        <div class="mt-4 black--text">
          {{ $t('MFA.activation.step4.paragraph1') }}
        </div>
        <div class="mt-4 black--text">
          {{ $t('MFA.activation.step4.paragraph2') }}
        </div>
        <div class="mt-4 black--text">
          {{ $t('MFA.activation.step4.paragraph3') }}
        </div>
        <div class="d-flex justify-center align-center mt-8">
          <img :src="svgCodes" alt="recovery codes" class="flex-shrink-0" />
        </div>
        <div class="d-flex justify-center align-center mt-6">
          <v-btn
            required
            color="primary"
            class="primarytext--text"
            @click="downloadRecoveryCodes"
          >
            {{ $t('MFA.buttons.download') }}
          </v-btn>
        </div>
        <div class="mt-4 black--text text-center mt-8 caption">
          {{ $t('MFA.activation.step4.paragraph4') }}
        </div>
        <div class="d-flex justify-center align-center mt-8">
          <v-btn
            required
            :disabled="!downloadedRecoveryCodes"
            color="secondary"
            class="secondarytext--text"
            @click="cont"
          >
            {{ $t('MFA.buttons.continue') }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="step == 5" cols="12" class="pa-0">
        <div class="d-flex justify-center align-center mt-16">
          <img :src="svgSuccess" alt="totp" class="flex-shrink-0" />
        </div>
        <div class="body-1 font-weight-bold mt-12 primary--text text-center">
          {{ $t('MFA.activation.step5.title') }}
        </div>
        <div class="mt-6 black--text text-center">
          {{ $t('MFA.activation.step5.subtitle') }}
        </div>
        <div class="d-flex justify-center align-center mt-8">
          <v-btn color="secondary" class="secondarytext--text" @click="close">
            {{ $t('MFA.buttons.close') }}
          </v-btn>
        </div>
      </v-col>
    </v-col>
    <v-col v-if="mode == modes.RECOVERY" cols="12">
      <v-col v-if="step == 0" cols="12" class="pa-0">
        <div class="secondary--text text-h5">
          {{ $t('MFA.recovery.step0.title') }}
        </div>
        <div class="mt-4 black--text font-weight-medium">
          {{ $t('MFA.recovery.step0.subtitle') }}
        </div>
        <div class="mt-8">
          {{ $t('MFA.recovery.step0.enterOtp') }}
        </div>
        <div class="d-flex justify-center align-center">
          <v-text-field
            ref="recoveryCode1"
            v-model="recoveryCode1"
            placeholder="0000"
            :error="!!recoveryCode1 && !/^\w{1,4}$/.test(recoveryCode1)"
            hide-details
            dense
            @paste="onRecoveryCodePaste"
            @keyup="onRecoveryCode1KeyUp"
          />
          <span class="mx-4">-</span>
          <v-text-field
            ref="recoveryCode2"
            v-model="recoveryCode2"
            :error="!!recoveryCode2 && !/^\w{1,4}$/.test(recoveryCode2)"
            placeholder="0000"
            hide-details
            dense
            @keyup="onRecoveryCode2KeyUp"
          />
          <span class="mx-4">-</span>
          <v-text-field
            ref="recoveryCode3"
            v-model="recoveryCode3"
            :error="!!recoveryCode3 && !/^\w{1,4}$/.test(recoveryCode3)"
            placeholder="0000"
            hide-details
            dense
            @keyup="onRecoveryCode3KeyUp"
          />
          <span class="mx-4">-</span>
          <v-text-field
            ref="recoveryCode4"
            v-model="recoveryCode4"
            :error="!!recoveryCode4 && !/^\w{1,4}$/.test(recoveryCode4)"
            placeholder="0000"
            hide-details
            dense
            @keyup="onRecoveryCode4KeyUp"
          />
        </div>
        <div
          class="caption primary--text text-decoration-underline text-right mt-2"
        >
          <a @click="cont">{{ $t('MFA.recovery.step0.lost') }}</a>
        </div>
        <div class="d-flex justify-center align-center mt-16">
          <v-btn class="mr-4" @click="cancel">
            {{ $t('MFA.buttons.cancel') }}
          </v-btn>
          <v-btn
            required
            :disabled="!recoveryCodesAreValid"
            color="secondary"
            class="secondarytext--text"
            @click="sendRecoveryCodes"
          >
            {{ $t('MFA.buttons.continue') }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="step == 1" cols="12" class="pa-0">
        <div class="secondary--text text-h5">
          {{ $t('MFA.recovery.step1.title') }}
        </div>
        <div class="mt-4 black--text">
          {{ $t('MFA.recovery.step1.paragraph') }}
        </div>
        <div class="d-flex justify-center align-center mt-8">
          <img :src="svgLost" alt="totp" class="flex-shrink-0" />
        </div>
        <div class="d-flex justify-center align-center mt-16">
          <v-btn class="mr-4" @click="back">
            {{ $t('MFA.buttons.cancel') }}
          </v-btn>
        </div>
      </v-col>
    </v-col>
    <v-col v-if="mode == modes.DEACTIVATION" cols="12">
      <div class="d-flex justify-center align-center mt-8">
        <img :src="svgActivation" alt="totp" class="flex-shrink-0" />
      </div>
      <div class="mt-6 black--text font-weight-bold text-center">
        {{ $t('MFA.deactivation.paragraph') }}
      </div>
      <div class="d-flex justify-center align-center mt-16">
        <v-btn class="mr-4" @click="cancel">
          {{ $t('MFA.buttons.cancel') }}
        </v-btn>
        <v-btn
          required
          color="secondary"
          class="secondarytext--text"
          @click="deactivate"
        >
          {{ $t('MFA.buttons.confirm') }}
        </v-btn>
      </div>
    </v-col>
    <v-dialog
      v-model="loading"
      persistent
      width="300"
      transition="fade-transition"
    >
      <v-card color="primary" dark>
        <v-card-title class="text-center primarytext--text d-block">
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export const MFA_MODES = {
  ACTIVATION: 'ACTIVATION',
  RECOVERY: 'RECOVERY',
  CONNECTION: 'CONNECTION',
  DEACTIVATION: 'DEACTIVATION',
  CHOICE: 'CHOICE',
}

export const MFA_TYPES = {
  HOTP: 0,
  TOTP: 1,
  NONE: -1,
}

export const MFA_METHODS = {
  NONE: 0,
  MANDATORY: 1,
  OPTIONAL: 2,
}

export const ACCOUNT_TYPES = {
  USER: 0,
  SYSTEM: 1,
}

import svgActivation from '../assets/mfa/activation.svg'
import svgHotp from '../assets/mfa/hotp.svg'
import svgTotp from '../assets/mfa/totp.svg'
import svgCodes from '../assets/mfa/codes.svg'
import svgSuccess from '../assets/mfa/success.svg'
import svgLost from '../assets/mfa/lost.svg'

import MfaService from '../services/MfaService'
import showToast from '../toasts'

export default {
  name: 'Mfa',
  props: {
    mode: {
      type: String,
      default: MFA_MODES.CONNECTION,
    },
    connectionType: {
      type: Number,
      default: MFA_TYPES.NONE,
    },
    user: {
      type: String,
      default: '',
    },
    password: {
      type: String,
      default: '',
    },
    mfaConfig: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'update:mode',
    'validated',
    'cancelled',
    'activated',
    'finished',
    'deactivated',
  ],
  data() {
    return {
      loading: false,
      step: 0,
      modes: MFA_MODES,
      types: MFA_TYPES,
      otp: '',
      svgActivation: svgActivation,
      svgHotp: svgHotp,
      svgTotp: svgTotp,
      svgCodes: svgCodes,
      svgSuccess: svgSuccess,
      svgLost: svgLost,
      downloadedRecoveryCodes: false,
      email: '',
      recoveryCode1: '',
      recoveryCode2: '',
      recoveryCode3: '',
      recoveryCode4: '',
      checkboxTotp: false,
      checkboxHotp: false,
      optedIn: true,
    }
  },
  computed: {
    mfaSecretKey() {
      return this.mfaConfig
        ? this.mfaConfig.Totp
          ? this.mfaConfig.Totp.SecretKey
          : null
        : null
    },
    qrCodeSrc() {
      return 'data:image/jpeg;base64,' + this.mfaConfig?.Totp?.Base64QRCode
    },
    emailIsValid() {
      return (
        !!this.email &&
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
      )
    },
    recoveryCodesAreValid() {
      return (
        /^\w{4}$/.test(this.recoveryCode1) &&
        /^\w{4}$/.test(this.recoveryCode2) &&
        /^\w{4}$/.test(this.recoveryCode3) &&
        /^\w{4}$/.test(this.recoveryCode4)
      )
    },
    type() {
      if (this.checkboxHotp) {
        return MFA_TYPES.HOTP
      }
      if (this.checkboxTotp) {
        return MFA_TYPES.TOTP
      }
      return MFA_TYPES.NONE
    },
    mfaParams() {
      let config = {}

      switch (this.type) {
        case MFA_TYPES.HOTP:
          config = {
            MfaType: this.mfaConfig.Mail.MfaType,
            SecretKey: this.mfaConfig.Mail.SecretKey,
            Issuer: this.mfaConfig.Mail.Issuer,
            RecoveryCodes: this.mfaConfig.Mail.RecoveryCodes,
          }
          break
        case MFA_TYPES.TOTP:
          config = {
            MfaType: this.mfaConfig.Totp.MfaType,
            SecretKey: this.mfaConfig.Totp.SecretKey,
            Issuer: this.mfaConfig.Totp.Issuer,
            RecoveryCodes: this.mfaConfig.Totp.RecoveryCodes,
          }
          break
      }

      return config
    },
  },
  watch: {
    checkboxHotp: function (val) {
      if (val) {
        this.checkboxTotp = false
      }
    },
    checkboxTotp: function (val) {
      if (val) {
        this.checkboxHotp = false
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancelled')
      this.reset()
    },
    cont() {
      this.step++
    },
    back() {
      if (
        this.mode == MFA_MODES.ACTIVATION &&
        this.type == MFA_TYPES.HOTP &&
        this.step == 3
      ) {
        //check if only hotp allowed and skip choice if necessary
        if (!this.mfaConfig?.Totp) {
          this.cancel()
        } else {
          this.step = 1
        }
        return
      }

      //check if only totp allowed and skip choice if necessary
      if (
        this.mode == MFA_MODES.ACTIVATION &&
        this.type == MFA_TYPES.TOTP &&
        this.step == 2 &&
        !this.mfaConfig?.Mail
      ) {
        this.cancel()
        return
      }

      this.step--
    },
    reset() {
      this.step = 0
      this.otp = ''
      this.downloadedRecoveryCodes = false
      this.checkboxHotp = false
      this.checkboxTotp = false
    },
    checkMfaConfigForActivation() {
      //two types
      if (!!this.mfaConfig.Totp && !!this.mfaConfig.Mail) {
        this.cont()
      }
      //one type
      if (!this.mfaConfig.Totp || !this.mfaConfig.Mail) {
        this.checkboxHotp = this.mfaConfig.Mail
        this.checkboxTotp = this.mfaConfig.Totp
        if (this.type == MFA_TYPES.HOTP) {
          this.sendMail()
        } else {
          this.step = 2
        }
      }
      //no types - error already returned on login
    },
    confirmChoice() {
      if (this.optedIn) {
        this.$emit('update:mode', MFA_MODES.ACTIVATION)
        this.checkMfaConfigForActivation()
      } else {
        this.optOut()
      }
    },
    optOutCallback() {
      this.$emit('optout')
    },
    selectMfaType() {
      switch (this.type) {
        case MFA_TYPES.HOTP:
          this.sendMail()
          break
        case MFA_TYPES.TOTP:
          this.cont()
          break
      }
    },
    handleMfaError(error) {
      let mfaResponse = error.response?.data?.MFA
      let inst = this

      switch (mfaResponse?.MfaMessageCode) {
        //invalid otp
        case 5:
          showToast(new Error(inst.$t('Login.mfaInvalidOtpCode')), inst)
          break
        //invalid recovery code
        case 6:
          showToast(new Error(inst.$t('Login.mfaInvalidRecoveryCode')), inst)
          break
        //no recovery codes available
        case 7:
          showToast(new Error(inst.$t('Login.mfaNoRecoveryCodes')), inst)
          break
        default:
          showToast(error, inst)
          break
      }
    },
    validate() {
      this.loading = true
      this.$store
        .dispatch('login', {
          name: this.user,
          password: this.password,
          otp: 'otp:' + this.otp,
        })
        .then(() => {
          this.$emit('validated')
        })
        .catch((error) => {
          this.handleMfaError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    switchToRecovery() {
      this.$emit('update:mode', MFA_MODES.RECOVERY)
    },
    copySecretKey() {
      navigator.clipboard.writeText(this.mfaConfig?.Totp?.SecretKey)
    },
    base64ToArrayBuffer(data) {
      let binaryString = window.atob(data)
      let binaryLen = binaryString.length
      let bytes = new Uint8Array(binaryLen)
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    downloadRecoveryCodes() {
      //get recovery codes
      this.loading = true

      const request = this.$store.getters.isLoggedIn
        ? {}
        : {
            LoginName: this.user,
            DomainName: this.$store.getters.siteConfig.DOMAIN,
            Password: this.password,
          }

      MfaService.getRecoveryCodes(request)
        .then((res) => {
          let saveAs = require('file-saver')
          let buffer = this.base64ToArrayBuffer(res.data)
          let blob = new Blob([buffer], { type: 'application/pdf' })
          saveAs(blob, 'recoverycodes.pdf')
          this.downloadedRecoveryCodes = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    activate() {
      //activate mfa
      this.loading = true

      const request = this.$store.getters.isLoggedIn
        ? {
            MfaParams: this.mfaParams,
            MfaCode: 'otp:' + this.otp,
          }
        : {
            LoginName: this.user,
            DomainName: this.$store.getters.siteConfig.DOMAIN,
            Password: this.password,
            MfaParams: this.mfaParams,
            MfaCode: 'otp:' + this.otp,
          }

      MfaService.activate(request)
        .then(() => {
          this.$emit('activated')
          this.cont()
          this.checkboxHotp = false
          this.checkboxTotp = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    deactivate() {
      //deactivate mfa
      this.loading = true
      MfaService.deactivate({})
        .then(() => {
          this.$emit('deactivated')
          this.cont()

          this.$store.dispatch('setMfaType', MFA_TYPES.NONE)
        })
        .finally(() => {
          this.loading = false
        })
    },
    optOut() {
      //opt out of mfa
      this.loading = true
      MfaService.deactivate({
        LoginName: this.user,
        Password: this.password,
        DomainName: this.$store.getters.siteConfig.DOMAIN,
      })
        .then(() => {
          this.$store.dispatch('setMfaType', MFA_TYPES.NONE)
          this.cont()
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendMail() {
      //send hotp code
      this.loading = true

      const request = this.$store.getters.isLoggedIn
        ? {
            SecretKey:
              this.$store.state.mfaType == MFA_TYPES.NONE
                ? this.mfaConfig?.Mail?.SecretKey
                : null,
          }
        : {
            DomainName: this.$store.getters.siteConfig.DOMAIN,
            LoginName: this.user,
            Password: this.password,
            SecretKey: this.mfaConfig?.Mail?.SecretKey,
          }

      MfaService.sendMail(request)
        .then((res) => {
          const msg = this.$t('MFA.connection.email-sent', {
            address: res.data,
          })

          this.$toast.success(msg, {
            position: 'top-right',
            timeout: 10000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: 'button',
            icon: 'v-icon mdi mdi-alert-circle',
            rtl: false,
            toastClassName: 'customToast',
          })

          this.step = 3
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('finished')
      this.reset()
    },
    sendRecoveryCodes() {
      //validate with recovery code
      this.loading = true
      this.$store
        .dispatch('login', {
          name: this.user,
          password: this.password,
          otp:
            'recovery:' +
            this.recoveryCode1 +
            '-' +
            this.recoveryCode2 +
            '-' +
            this.recoveryCode3 +
            '-' +
            this.recoveryCode4,
        })
        .then(() => {
          this.$emit('validated')
        })
        .catch((error) => {
          this.handleMfaError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    switchType(type) {
      if (this.type != type) {
        this.type = type
      } else {
        this.type = MFA_TYPES.NONE
      }
    },
    onRecoveryCode1KeyUp() {
      if (/^\w{4}$/.test(this.recoveryCode1)) {
        this.$refs.recoveryCode2?.focus()
      }
    },
    onRecoveryCode2KeyUp() {
      if (this.recoveryCode2.length == 0) {
        this.$refs.recoveryCode1?.focus()
      }
      if (/^\w{4}$/.test(this.recoveryCode2)) {
        this.$refs.recoveryCode3?.focus()
      }
    },
    onRecoveryCode3KeyUp() {
      if (this.recoveryCode3.length == 0) {
        this.$refs.recoveryCode2?.focus()
      }
      if (/^\w{4}$/.test(this.recoveryCode3)) {
        this.$refs.recoveryCode4?.focus()
      }
    },
    onRecoveryCode4KeyUp() {
      if (this.recoveryCode4.length == 0) {
        this.$refs.recoveryCode3?.focus()
      }
    },
    onRecoveryCodePaste(event) {
      const pastedValue = event.clipboardData.getData('text/plain').trim()
      if (/^[\w-]{19}$/.test(pastedValue)) {
        event.preventDefault()
        this.recoveryCode1 = pastedValue.substring(0, 4)
        this.recoveryCode2 = pastedValue.substring(5, 9)
        this.recoveryCode3 = pastedValue.substring(10, 14)
        this.recoveryCode4 = pastedValue.substring(15, 19)
        this.$refs.recoveryCode4?.focus()
        this.$refs.recoveryCode4?.blur()
      }
    },
  },
}
</script>
