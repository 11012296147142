import ApiService from '../services/ApiService'

const URL_FINDUSERS = '/api/UserManagement/FindUsers'
const URL_FINDUSERSBYNAME = '/api/UserManagement/FindUsersByName'
const URL_FINDUSERSBYEMAIL = '/api/UserManagement/FindUsersByEmail'
const URL_FINDUSERSBYPROFILE = '/api/UserManagement/FindUsersByProfile'
const URL_SEARCHGROUPS = '/api/UserManagement/GetGroups'
const URL_GETPROFILEVARIABLES = '/api/UserManagement/GetProfileVariables'
const URL_ADDUSER = '/api/UserManagement/AddUser'
const URL_EDITUSER = '/api/UserManagement/UpdateUser'
const URL_DELETEUSER = '/api/UserManagement/DeleteUser'
const URL_ADDGROUP = '/api/UserManagement/AddGroup'
const URL_UPDATEGROUP = '/api/UserManagement/UpdateGroup'
const URL_DELETEGROUP = '/api/UserManagement/DeleteGroup'
const URL_GETAUDIT = '/api/UserManagement/GetAuditLogs'
const URL_CHANGEPASSWORD = '/api/UserManagement/ChangePassword'
const URL_GETPASSWORDRULES = '/api/UserManagement/GetPasswordRules'

const UserManagementService = {
  getAudit(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETAUDIT, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  findUsers(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_FINDUSERS, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  findUsersByName(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_FINDUSERSBYNAME, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  findUsersByEmail(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_FINDUSERSBYEMAIL, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  findUsersByProfile(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_FINDUSERSBYPROFILE, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  searchGroups(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_SEARCHGROUPS, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getProfileVariables(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETPROFILEVARIABLES, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addUser(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_ADDUSER, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  editUser(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_EDITUSER, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteUser(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_DELETEUSER, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addGroup(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_ADDGROUP, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateGroup(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_UPDATEGROUP, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteGroup(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_DELETEGROUP, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  changePassword(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_CHANGEPASSWORD, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPasswordRules(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETPASSWORDRULES, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default UserManagementService
