<template>
  <v-container class="fill-height login-container" fluid>
    <v-row align="center" justify="center">
      <v-card
        class="elevation-12 ma-4"
        width="400"
        style="max-width: calc(100% - 24px)"
        rounded="0"
      >
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title class="primarytext--text">
            {{ $t('Login.login') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-img
            :src="$store.getters.siteConfig.LOGIN_LOGO"
            contain
            max-height="125"
            max-width="100%"
            class="mb-4 mx-auto"
          />

          <v-form
            ref="formLogin"
            v-model="login_valid"
            lazy-validation
            @submit.prevent="login"
          >
            <v-text-field
              v-model="login_name"
              required
              placeholder=" "
              persistent-placeholder
              hide-details="auto"
              type="name"
              :label="$t('Login.name')"
              :rules="rules_Name"
              class="mb-1"
            />

            <v-text-field
              v-model="login_password"
              required
              placeholder=" "
              persistent-placeholder
              hide-details="auto"
              :type="hidePassword ? 'password' : 'text'"
              :label="$t('Login.password')"
              :rules="rules_Password"
              :append-icon="hidePassword ? 'ci-eye_barred' : 'ci-eye'"
              @keydown.enter="login"
              @click:append="() => (hidePassword = !hidePassword)"
            />

            <div class="d-flex justify-end">
              <v-checkbox
                v-model="checkbox_remember"
                hide-details
                dense
                :label="$t('Login.remember')"
                @change="onRememberChange"
              />
            </div>

            <a
              v-if="!disableAccountManagement"
              class="text-right text-decoration-underline d-block mt-1 text-caption"
              @click="showResetPassword"
            >
              {{ $t('Login.resetPassword') }}
            </a>

            <a
              v-if="!disableAccountManagement && showChangePasswordLink"
              class="text-right text-decoration-underline d-block mt-1 text-caption"
              @click="showChangePassword"
            >
              {{ $t('Login.changePassword') }}
            </a>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center">
          <v-btn
            required
            :disabled="!login_valid"
            :loading="loading"
            color="secondary"
            class="ml-2 secondarytext--text px-4"
            @click="login"
          >
            {{ $t('Login.login') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-dialog
      v-model="changePassword_dialog"
      width="500"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            {{ $t('Login.changePassword') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="changePassword_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12">
              <v-form
                ref="formChangePassword"
                v-model="changePassword_valid"
                lazy-validation
                @submit.prevent="changePassword"
              >
                <v-text-field
                  v-model="changePassword_name"
                  required
                  placeholder=" "
                  persistent-placeholder
                  class="mt-4"
                  type="name"
                  :rules="rules_Name"
                  :label="$t('Login.name')"
                />
                <v-text-field
                  v-model="changePassword_currentPassword"
                  required
                  placeholder=" "
                  persistent-placeholder
                  type="password"
                  :rules="rules_Password"
                  :label="$t('Login.currentPassword')"
                />
                <v-text-field
                  v-model="changePassword_newPassword"
                  required
                  placeholder=" "
                  type="password"
                  :rules="rules_Password"
                  :label="$t('Login.newPassword')"
                />
                <v-text-field
                  v-model="changePassword_newPasswordConfirm"
                  required
                  placeholder=" "
                  persistent-placeholder
                  type="password"
                  :rules="rules_ConfirmPassword"
                  :label="$t('Login.newPasswordConfirm')"
                  @keydown.enter="changePassword"
                />
                <v-row class="ma-0 mb-6">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <div v-bind="attrs" class="mt-4" v-on="on">
                        <v-icon class="mr-2"> ci-search </v-icon>
                        <span>{{ $t('UserManagement.passwordRules') }}</span>
                      </div>
                    </template>
                    <p :class="{ 'mb-0': passwordRegexMessage == '' }">
                      {{
                        $t('UserManagement.passwordMinimumLength', {
                          length: passwordMinLength,
                        })
                      }}
                    </p>
                    <p v-if="passwordRegexMessage != ''" class="mb-0">
                      {{ passwordRegexMessage }}
                    </p>
                  </v-tooltip>
                </v-row>
                <v-btn
                  required
                  :disabled="!changePassword_valid"
                  :loading="loading"
                  color="secondary"
                  class="secondarytext--text"
                  @click="changePassword"
                >
                  <v-icon left> mdi-pen-lock </v-icon>
                  {{ $t('Login.changePassword') }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="resetPassword_dialog"
      width="500"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            {{ $t('Login.resetPassword') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="resetPassword_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12">
              <v-form
                ref="formResetPassword"
                v-model="resetPassword_valid"
                lazy-validation
                @submit.prevent="resetPassword"
              >
                <v-text-field
                  v-model="resetPassword_name"
                  required
                  placeholder=" "
                  persistent-placeholder
                  type="name"
                  :rules="rules_Name"
                  :label="$t('Login.name')"
                  class="mt-4"
                />
                <v-text-field
                  v-model="resetPassword_email"
                  required
                  persistent-placeholder
                  type="email"
                  :rules="rules_Email"
                  :label="$t('Login.email')"
                  @keydown.enter="resetPassword"
                />
                <v-btn
                  required
                  :disabled="!resetPassword_valid"
                  :loading="loading"
                  color="secondary"
                  class="secondarytext--text"
                  @click="resetPassword"
                >
                  <v-icon left> mdi-lock-reset </v-icon>
                  {{ $t('Login.resetPassword') }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMfaDialog"
      width="500"
      transition="fade-transition"
      persistent
    >
      <v-card>
        <v-card-text class="pt-4">
          <MFA
            :mode="mfaMode"
            :user="login_name"
            :password="login_password"
            :mfa-config="mfaConfig"
            :connection-type="mfaType"
            @update:mode="setMfaMode"
            @cancelled="showMfaDialog = false"
            @finished="showMfaDialog = false"
            @validated="loginCallback"
            @optout="onOptOutMfa"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AuthenticationService from '../services/AuthenticationService'
import { MFA_MODES, MFA_TYPES, MFA_METHODS } from '../components/MFA.vue'
import MFA from '../components/MFA.vue'
import showToast from '../toasts'
import store from '../store'
import MfaService from '../services/MfaService'
import UserManagementService from '../services/UserManagementService'

export default {
  components: { MFA },
  data() {
    return {
      loading: false,
      hidePassword: true,
      showChangePasswordLink: false,
      login_name: '',
      login_password: '',
      login_valid: true,
      resetPassword_name: '',
      resetPassword_email: '',
      resetPassword_valid: true,
      resetPassword_dialog: false,
      changePassword_name: '',
      changePassword_currentPassword: '',
      changePassword_newPassword: '',
      changePassword_newPasswordConfirm: '',
      changePassword_valid: true,
      changePassword_dialog: false,
      checkbox_remember: false,
      showMfaDialog: false,
      mfaMode: MFA_MODES.ACTIVATION,
      mfaConfig: {},
      mfaType: MFA_TYPES.NONE,
      passwordMinLength: 6,
      passwordRegex: '',
      passwordRegexMessage: '',
    }
  },
  computed: {
    rules_Name() {
      return [(v) => !!v || this.$t('Login.nameRequired')]
    },
    rules_Email() {
      return [
        (v) => !!v || this.$t('Login.emailRequired'),
        (v) => /.+@.+/.test(v) || this.$t('Login.invalidEmail'),
      ]
    },
    rules_ConfirmPassword() {
      return [
        (v) =>
          v == this.changePassword_newPassword ||
          this.$t('Login.passwordsDoNotMatch'),
        (v) => !!v || this.$t('Login.passwordRequired'),
      ]
    },
    rules_Password() {
      return [(v) => !!v || this.$t('Login.passwordRequired')]
    },
    disableAccountManagement() {
      return (
        !!this.$store.getters.siteConfig.DISABLE_ACCOUNTMANAGEMENT &&
        this.$store.getters.siteConfig.DISABLE_ACCOUNTMANAGEMENT
      )
    },
  },
  mounted() {
    let tp = this.$route.query.tp
    let un = this.$route.query.un

    if (un) {
      this.login_name = un
    }

    let ls = window.localStorage.getItem(
      'eas-auth-' + this.$store.getters.siteConfig.DOMAIN
    )

    if (ls) {
      try {
        let parsed = JSON.parse(window.atob(ls))
        if (parsed) {
          this.checkbox_remember = true
          this.login_name = parsed.login
          this.login_password = parsed.password
        }
      } catch (e) {
        console.log(e)
      }
    }

    if (!!tp && !!un) {
      this.changePassword_name = un
      this.changePassword_currentPassword = tp
      this.showChangePasswordLink = true
      this.showChangePassword()

      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      })
    }
  },
  methods: {
    getPasswordRules() {
      let inst = this

      let request = {}

      request.Domain = this.$store.getters.siteConfig.DOMAIN
      request.Issuer = 'Built-In'

      this.loading = true

      return new Promise((resolve, reject) => {
        UserManagementService.getPasswordRules(request)
          .then((response) => {
            inst.passwordMinLength = response.data.MinimumLength
            inst.passwordRegex = response.data.Regex
            inst.passwordRegexMessage = response.data.RegexMessage
            resolve()
          })
          .catch(function (error) {
            reject(error)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    setMfaMode(mode) {
      this.mfaMode = mode
    },
    showChangePassword() {
      this.getPasswordRules().then(() => {
        this.changePassword_dialog = true
        if (this.$refs.formChangePassword) {
          this.$refs.formChangePassword.reset()
        }
      })
    },
    onOptOutMfa() {
      this.showMfaDialog = false
      this.login()
    },
    changePassword() {
      let inst = this
      this.changePassword_valid = this.$refs.formChangePassword.validate()
      if (this.changePassword_valid) {
        this.loading = true
        let request = {}
        request.Login = this.changePassword_name
        request.OldPassword = this.changePassword_currentPassword
        request.NewPassword = this.changePassword_newPassword
        request.DomainName = this.$store.getters.siteConfig.DOMAIN
        AuthenticationService.changePassword(request)
          .then(function () {
            inst.login_name = inst.changePassword_name
            inst.login_password = inst.changePassword_newPassword

            inst.changePassword_dialog = false
            inst.changePassword_name = inst.user
            inst.changePassword_newPassword = ''
            inst.changePassword_newPasswordConfirm = ''
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    showResetPassword() {
      this.resetPassword_name = this.login_name
      this.resetPassword_dialog = true
      if (this.$refs.formResetPassword) {
        this.$refs.formResetPassword.reset()
      }
    },
    onRememberChange() {
      if (!this.checkbox_remember) {
        window.localStorage.removeItem(
          'eas-auth-' + this.$store.getters.siteConfig.DOMAIN
        )
      }
    },
    loginCallback() {
      let inst = this
      let route = this.$route.query.path

      this.showMfaDialog = false

      inst.$store.dispatch('setRoutes', inst).then(function () {
        let easGroupResult = inst.$store.getters.routes.filter(
          (q) => q.name == 'Navigation.eas'
        )
        if (route) {
          inst.$router.push(route)
          return
        }
        if (
          easGroupResult.length == 1 &&
          easGroupResult[0].children != null &&
          Array.isArray(easGroupResult[0].children) &&
          easGroupResult[0].children.length > 0
        ) {
          easGroupResult[0].children.sort((a, b) =>
            a.name.localeCompare(b.name)
          )
          inst.$router.push(easGroupResult[0].children[0].path)
        } else {
          inst.$router.push('/')
        }
      })
    },
    login() {
      let inst = this
      this.login_valid = this.$refs.formLogin.validate()
      if (this.login_valid) {
        this.loading = true

        if (this.checkbox_remember) {
          window.localStorage.setItem(
            'eas-auth-' + this.$store.getters.siteConfig.DOMAIN,
            window.btoa(
              JSON.stringify({
                login: inst.login_name,
                password: inst.login_password,
              })
            )
          )
        }

        let name = this.login_name
        let password = this.login_password.padEnd(10, '0')
        this.$store
          .dispatch('login', { name, password })
          .then(() => {
            this.$store.dispatch('setMfaType', MFA_TYPES.NONE)
            inst.loginCallback()
          })
          .catch((error) => {
            let msg =
              error.response?.data?.Message || error.response?.statusText || ''

            let mfaResponse = error.response?.data?.MFA

            if (mfaResponse) {
              try {
                switch (mfaResponse.MfaMessageCode) {
                  //MFA_CONFIG_REQUIRED -> show mfa component in activation mode
                  case 1:
                    mfaResponse.MfaConfigs = JSON.parse(
                      mfaResponse.MfaConfigs.replace(/\\/g, '')
                        .replace(/"{/g, '{')
                        .replace(/}"/g, '}')
                    )

                    this.mfaConfig = mfaResponse.MfaConfigs

                    this.loading = true

                    MfaService.getMfaMethod({
                      DomainName: this.$store.getters.siteConfig.DOMAIN,
                    })
                      .then((r) => {
                        if (r.data.MfaMethod == MFA_METHODS.OPTIONAL) {
                          this.mfaMode = MFA_MODES.CHOICE
                        } else {
                          this.mfaMode = MFA_MODES.ACTIVATION
                        }

                        this.showMfaDialog = true
                      })
                      .finally(() => {
                        this.loading = false
                      })

                    break
                  //NOT ALLOWED MFA TYPE -> show toast
                  case 2:
                    showToast(
                      new Error(inst.$t('Login.mfaNoAllowedTypes')),
                      inst
                    )
                    break
                  //TOTP_NEEDED & HOTP_NEEDED -> show mfa component in connection mode
                  case 3:
                  case 4:
                    this.mfaMode = MFA_MODES.CONNECTION
                    this.showMfaDialog = true
                    this.mfaType =
                      mfaResponse.MfaMessageCode == 3
                        ? MFA_TYPES.TOTP
                        : MFA_TYPES.HOTP

                    store.dispatch(
                      'setMfaType',
                      mfaResponse.MfaMessageCode == 3
                        ? MFA_TYPES.TOTP
                        : MFA_TYPES.HOTP
                    )
                    break
                  //INVALID OTP -> show toast
                  case 5:
                    showToast(
                      new Error(inst.$t('Login.mfaInvalidOtpCode')),
                      inst
                    )
                    break
                  default:
                    showToast(error, inst)
                    break
                }
              } catch {
                showToast(error, inst)
              }
            } else {
              if (
                !!msg &&
                (msg.indexOf('must change password') !== -1 ||
                  msg.indexOf('doit changer de mot de passe') !== -1 ||
                  msg.indexOf('moet aangepast worden') !== -1)
              ) {
                inst.changePassword_name = inst.login_name
                inst.changePassword_currentPassword = inst.login_password
                inst.showChangePasswordLink = true
                inst.showChangePassword()
              }
              showToast(error, inst)
            }
          })
          .finally(() => (this.loading = false))
      }
    },
    copy(value) {
      const el = document.createElement('textarea')
      el.addEventListener('focusin', (e) => e.stopPropagation())
      el.value = value
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$toast.success(this.$t('Global.copied'), {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: 'v-icon mdi mdi-check',
        rtl: false,
        toastClassName: 'customToast',
      })
    },
    resetPassword() {
      let inst = this
      this.resetPassword_valid = this.$refs.formResetPassword.validate()
      if (this.resetPassword_valid) {
        this.loading = true
        let request = {}
        request.Login = this.resetPassword_name
        request.Mail = this.resetPassword_email
        request.DomainName = this.$store.getters.siteConfig.DOMAIN
        request.Link =
          window.location.origin +
          window.location.pathname +
          '#/login?tp={{password}}&un=' +
          request.Login
        AuthenticationService.resetPassword(request)
          .then(function () {
            inst.resetPassword_name = ''
            inst.resetPassword_email = ''
            inst.resetPassword_dialog = false
            inst.login_name = inst.resetPassword_name
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
  },
}
</script>
