<template>
  <v-container fluid class="grey lighten-2 flexContainer">
    <v-card class="w-full h-full d-flex flex-column" rounded="0">
      <v-card-title class="pa-1 pl-2 secondary">
        <slot name="title">
          <v-btn
            x-small
            fab
            dark
            rounded
            color="secondarytext"
            elevation="1"
            class="mr-2 mt-1 mb-1"
            @click="getAudit"
          >
            <v-icon class="secondary--text"> mdi-autorenew </v-icon>
          </v-btn>
          <span class="font-weight-medium text-h6 secondarytext--text">
            {{ $t('Navigation.usermanagement_children.' + $route.name) }}
          </span>
        </slot>
      </v-card-title>
      <v-divider />
      <v-row style="flex: initial; flex-shrink: 0" class="ma-0 pt-0 pb-0">
        <div class="white pb-4 pa-2 pl-4 pr-4 filterRow">
          <div class="filters">
            <v-menu
              v-model="selectDateFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDateFrom"
                  single-line
                  hide-details
                  data-type="date"
                  class="text-caption mr-2"
                  readonly
                  v-bind="attrs"
                  :clearable="true"
                  append-icon="ci-calendar_from"
                  :label="$t('UserManagementAudit.dateFrom')"
                  v-on="on"
                  @click:clear="clearDateFrom"
                />
              </template>
              <v-date-picker
                v-model="dateFrom"
                @input="selectDateFrom = false"
              />
            </v-menu>
            <v-menu
              v-model="selectDateTo"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDateTo"
                  single-line
                  hide-details
                  data-type="date"
                  class="text-caption mr-2"
                  readonly
                  v-bind="attrs"
                  :clearable="true"
                  append-icon="ci-calendar_to"
                  :label="$t('UserManagementAudit.dateTo')"
                  v-on="on"
                  @click:clear="clearDateTo"
                />
              </template>
              <v-date-picker v-model="dateTo" @input="selectDateTo = false" />
            </v-menu>
            <v-text-field
              v-model="action"
              hide-details
              :label="$t('UserManagementAudit.action')"
              :clearable="true"
              class="text-caption mr-2"
              @click:clear="clearAction"
              @keydown.enter="getAudit"
            />
            <v-text-field
              v-model="user"
              hide-details
              :label="$t('UserManagementAudit.user')"
              :clearable="true"
              class="text-caption mr-2"
              @click:clear="clearUser"
              @keydown.enter="getAudit"
            />
            <v-text-field
              v-model="primaryReference"
              hide-details
              :label="$t('UserManagementAudit.primaryReference')"
              :clearable="true"
              class="text-caption mr-2"
              @click:clear="clearPrimaryReference"
              @keydown.enter="getAudit"
            />
            <v-select
              v-model="messageType"
              hide-details
              :debounce-search="0"
              :clearable="true"
              :items="messageTypes"
              :label="$t('UserManagementAudit.messageType')"
              item-text="text"
              item-value="value"
              class="text-caption mr-2"
              @click:clear="clearMessageType"
              @change="getAudit"
            />
          </div>
          <v-btn required :loading="loading" @click="getAudit">
            <v-icon left color="primary"> mdi-magnify </v-icon>
            {{ $t('UserManagementAudit.search') }}
          </v-btn>
        </div>
      </v-row>
      <v-row class="ma-0 pb-0 pt-0" style="overflow: auto">
        <argus-grid
          ref="argusGrid"
          :items="items"
          :headers="headers"
          :load-data="getAudit"
          :total-results="totalResults"
          :loading="loading"
        >
          <template slot="renderer.MessageType" slot-scope="{ item }">
            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="item.MessageType == 0"
                  color="blue"
                  icon
                  v-bind="attrs"
                  @click="showAuditInfo(item)"
                  v-on="on"
                >
                  <v-icon>mdi-information-variant</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('UserManagementAudit.info') }}</span>
            </v-tooltip>
            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="item.MessageType == 1"
                  color="red"
                  icon
                  v-bind="attrs"
                  @click="showAuditInfo(item)"
                  v-on="on"
                >
                  <v-icon>mdi-alert</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('UserManagementAudit.warning') }}</span>
            </v-tooltip>
            <v-tooltip bottom transition="fade-transition">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="item.MessageType == 2"
                  color="orange"
                  icon
                  v-bind="attrs"
                  @click="showAuditInfo(item)"
                  v-on="on"
                >
                  <v-icon>mdi-information-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('UserManagementAudit.error') }}</span>
            </v-tooltip>
          </template>
          <template slot="renderer.UtcTime" slot-scope="{ item }">
            {{
              moment(item.UtcTime, 'YYYY-MM-DDThh:mm:ss[Z]').format(
                'DD/MM/YYYY HH:mm:ss'
              )
            }}
          </template>
        </argus-grid>
      </v-row>
    </v-card>

    <v-dialog v-model="auditDialog" width="500" transition="fade-transition">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            dark
            color="primary"
            class="text-center primarytext--text"
            style="display: block"
          >
            {{ $t('UserManagementAudit.info') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="auditDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 fixedHeight">
          <div
            v-for="(i, index) in auditItem.filter((q) => q.value != null)"
            :key="i.label"
          >
            <label class="font-weight-medium mr-8 grey--text text--darken-4">{{
              i.label
            }}</label>
            <v-spacer />
            <vue-json-pretty
              v-if="typeof i.value === 'object'"
              :data="i.value"
              :show-double-quotes="false"
            />
            <span v-else>{{ i.value }}</span>
            <v-divider
              v-if="
                index + 1 != auditItem.filter((q) => q.value != null).length
              "
              class="mt-1 mb-1"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
      transition="fade-transition"
    >
      <v-card color="primary" dark>
        <v-card-title
          class="text-center primarytext--text"
          style="display: block"
        >
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import UserManagementService from '../services/UserManagementService'
import moment from 'moment'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    ArgusGrid: () => import('../components/ArgusGrid'),
    VueJsonPretty: () => import('vue-json-pretty'),
  },
  data() {
    return {
      items: [],
      dateFrom: '',
      dateTo: '',
      action: '',
      user: '',
      messageType: '',
      primaryReference: '',
      loading: false,
      auditDialog: false,
      auditItem: [],
      selectDateFrom: false,
      selectDateTo: false,
      totalResults: 0,
    }
  },
  computed: {
    messageTypes() {
      return [
        { text: this.$t('UserManagementAudit.info'), value: 0 },
        { text: this.$t('UserManagementAudit.error'), value: 1 },
        { text: this.$t('UserManagementAudit.warning'), value: 2 },
      ]
    },
    headers() {
      return [
        {
          text: this.$t('UserManagementAudit.messageType'),
          value: 'MessageType',
          sortable: false,
          renderer: true,
          align: 'center',
          width: '150px',
        },
        {
          text: this.$t('UserManagementAudit.utcTime'),
          value: 'UtcTime',
          sortable: false,
          width: '175px',
          renderer: true,
        },
        {
          text: this.$t('UserManagementAudit.action'),
          value: 'ShortDescription',
          sortable: false,
        },
        {
          text: this.$t('UserManagementAudit.user'),
          value: 'UserName',
          sortable: false,
        },
        {
          text: this.$t('UserManagementAudit.primaryReference'),
          value: 'PrimaryReferenceValue',
          sortable: false,
        },
      ]
    },
    formattedDateFrom() {
      if (this.dateFrom == '') {
        return ''
      } else {
        return moment(this.dateFrom, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    },
    formattedDateTo() {
      if (this.dateTo == '') {
        return ''
      } else {
        return moment(this.dateTo, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    },
  },
  methods: {
    isJSON(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    showAuditInfo(item) {
      this.auditItem = []
      Object.keys(item).forEach((e) =>
        this.auditItem.push({ label: e, value: item[e] })
      )
      this.auditDialog = true
    },
    clearAction() {
      this.action = ''
      this.getAudit()
    },
    clearMessageType() {
      this.messageType = ''
      this.getAudit()
    },
    clearDateFrom() {
      this.dateFrom = ''
      this.getAudit()
    },
    clearDateTo() {
      this.dateTo = ''
      this.getAudit()
    },
    clearUser() {
      this.user = ''
      this.getAudit()
    },
    clearPrimaryReference() {
      this.primaryReference = ''
      this.getAudit()
    },
    getAudit() {
      let inst = this
      let request = {}

      inst.loading = true

      request.Domain = this.$store.getters.siteConfig.DOMAIN
      request.PageIndex = this.$refs.argusGrid.options.page
      request.PageSize = this.$refs.argusGrid.options.itemsPerPage
      request.AuditFilter = {}
      request.AuditFilter.Action = this.action
      request.AuditFilter.MessageType = this.messageType
      request.AuditFilter.From = this.dateFrom
      request.AuditFilter.To = this.dateTo
      request.AuditFilter.User = this.user
      request.AuditFilter.Reference = this.primaryReference

      UserManagementService.getAudit(request)
        .then((resp) => {
          inst.items = resp.data.data
          inst.totalResults = resp.data.itemsCount
        })
        .finally(function () {
          inst.loading = false
        })
    },
  },
}
</script>

<style scoped>
.fixedHeight {
  max-height: 75vh;
  overflow: auto;
}
</style>
