import ApiService from '../services/ApiService'

const URL_GETCONFIG = '/api/Site/GetConfig'

const SiteService = {
  getConfig(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_GETCONFIG, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default SiteService
