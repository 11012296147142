import ApiService from '../services/ApiService'

const URL_LOGON = '/api/Account/Logon'
const URL_SSO = '/api/Account/SsoLogon'
const URL_RESETPASSWORD = '/api/Account/ResetPassword'
const URL_CHANGEPASSWORD = '/api/Account/ChangePassword'
const URL_PREFERENCES = '/api/Account/GetUserProfileAndPreferences'

const AuthenticationService = {
  login(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_LOGON, request, { skipToast: true })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  sso(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_SSO, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  resetPassword(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_RESETPASSWORD, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  changePassword(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_CHANGEPASSWORD, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getUserProfileAndPreferences(request) {
    return new Promise((resolve, reject) => {
      ApiService.post(URL_PREFERENCES, request)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default AuthenticationService
