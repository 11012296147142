<template>
  <docflow :key="$route.params.id" ref="docflow" />
</template>

<script>
export default {
  components: {
    Docflow: () => import('../components/DocflowGrid'),
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.docflow.lockId != '') {
      this.$refs.docflow.cancelTask(this.$refs.docflow.selected[0])
      next()
    } else {
      next()
    }
  },
}
</script>
